const getAegis = () => {
  if (import.meta.env.MODE === 'development') {
    return new Proxy(
      {},
      {
        get: () => () => {},
      },
    );
  }

  return window.aegis;
};

export default getAegis();
