import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'modules/store';
import unset from 'lodash/unset';
import cloneDeep from 'lodash/cloneDeep';
import { QQShopBrandInfo, QQShopDetail } from 'services/shop';
import merge from 'lodash/merge';
import { updateObjByChainKeys } from 'utils';
import { v4 } from 'uuid';

const namespace = 'settleV2';

const initialState: {
  // 入驻时的表单数据快照，仅用来获取表单实时值，不要用来提交数据，因为没有validator，会存在非法值
  formSnapshot: DeepPartial<QQShopDetail>;

  formBrandSnapshot: {
    brandInfos: DeepPartial<QQShopBrandInfo['brandInfos']>;
  };

  // 类目资质信息快照
  formCategoryTabSnapshot: DeepPartial<QQShopDetail['categoryDetail']['tabDetails']>;

  categoryChangeIdsSnapshot: number; // 类目id改变数量

  // 全量更新快照是否完成
  detailsUpdated: boolean;
  // 全量更新快照是否完成
  brandUpdated: boolean;
  // 全量更新快照是否完成
  detailsTabUpdated: boolean;
  // 用于资质变更-新旧总快照对比
  oldFormSnapshot: DeepPartial<QQShopDetail>;
  // 用于资质变更-新旧品牌快照对比
  oldFormBrandSnapshot: {
    brandInfos: DeepPartial<QQShopBrandInfo['brandInfos']>;
  };
  // 旧的类目变更快照
  oldFormCategorySnapshot: DeepPartial<QQShopDetail['categoryDetail']>;
  noDraft: boolean;
} = {
  formSnapshot: {
    categoryDetail: { categoryDetails: [] },
  },
  formBrandSnapshot: {
    brandInfos: [{}],
  },
  oldFormBrandSnapshot: {
    brandInfos: [{}],
  },
  oldFormSnapshot: {},
  formCategoryTabSnapshot: [],
  categoryChangeIdsSnapshot: 0,
  detailsUpdated: false,
  brandUpdated: false,
  detailsTabUpdated: false,
  noDraft: true,
  oldFormCategorySnapshot: {},
};

const settleV2Slice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    reset(state) {
      state.formSnapshot = {};
      state.formBrandSnapshot = {
        brandInfos: [],
      };
      state.formCategoryTabSnapshot = [];
    },

    updateNoDraft: (state, action: PayloadAction<boolean>) => {
      state.noDraft = action.payload;
    },

    updateBrandUpdated: (state, action: PayloadAction<boolean>) => {
      state.brandUpdated = action.payload;
    },

    updateDetailsUpdated: (state, action: PayloadAction<boolean>) => {
      state.detailsUpdated = action.payload;
    },

    // 链式更新快照的属性
    updateSnapshot: (state, action: PayloadAction<Record<string, any>>) => {
      const clone = cloneDeep(state.formSnapshot);
      updateObjByChainKeys(clone, action.payload);
      state.formSnapshot = clone;
    },
    // 链式更新资质类目快照
    updateCategorySnapshot: (
      state,
      action: PayloadAction<DeepPartial<QQShopDetail['categoryDetail']['categoryDetails']>>,
    ) => {
      const clone = cloneDeep(state.formSnapshot?.categoryDetail?.categoryDetails ?? {});
      updateObjByChainKeys(clone, action.payload);
      state.formSnapshot.categoryDetail.categoryDetails = clone;
    },
    // 删除资质类目快照
    delCategorySnapshot: (
      state,
      action: PayloadAction<DeepPartial<QQShopDetail['categoryDetail']['categoryDetails']>>,
    ) => {
      const noDelIds = action.payload.map((item) => item.id);
      const { categoryDetails = [] } = state.formSnapshot.categoryDetail;
      state.formSnapshot.categoryDetail.categoryDetails = categoryDetails?.filter((item) => noDelIds.includes(item.id));
    },
    // 清空资质类目快照
    clearCategorySnapshot: (state) => {
      state.formSnapshot.categoryDetail.categoryDetails = [];
    },
    // 链式删除快照的属性 eg: {payload: 'baseInfo.shopType'},就会删除 formSnapshot.baseInfo.shopType
    deleteSnapShotKeys: (state, action: PayloadAction<string>) => {
      const clone = cloneDeep(state.formSnapshot);
      unset(clone, action.payload);
      state.formSnapshot = clone;
    },
    // 全量写快照，用于表单从服务端更新
    writeSnapshot: (state, action: PayloadAction<DeepPartial<QQShopDetail>>) => {
      state.formSnapshot = action.payload;
      state.detailsUpdated = true;
    },

    // 链式更新品牌快照的属性
    updateBrandSnapshot: (state, action: PayloadAction<Record<string, any>>) => {
      const clone = cloneDeep(state.formBrandSnapshot);
      updateObjByChainKeys(clone, action.payload);
      state.formBrandSnapshot = clone;
    },

    addBrandInfo: (state) => {
      state.formBrandSnapshot.brandInfos = state.formBrandSnapshot.brandInfos.concat({
        uuid: v4(),
      });
    },

    // 全量写快照
    updateBrandInfo: (state, action: PayloadAction<DeepPartial<QQShopBrandInfo>>) => {
      const v = merge({}, state.formBrandSnapshot, action.payload);
      state.formBrandSnapshot = {
        brandInfos: v.brandInfos.map((info) => ({
          ...info,
          uuid: info?.brandId ? info.brandId : info?.uuid ?? v4(),
        })),
      };
      state.brandUpdated = true;
    },

    removeBrandInfo: (state, action: PayloadAction<number>) => {
      state.formBrandSnapshot = {
        brandInfos: state.formBrandSnapshot.brandInfos.filter((v, i) => i !== action.payload),
      };
    },

    // 删除资质类目tab快照
    removeCategoryTabSnapshot: (state, action: PayloadAction<number>) => {
      state.formCategoryTabSnapshot = state.formCategoryTabSnapshot?.filter((v, i) => i !== action.payload);
    },

    // 链式更新快照的属性
    updateCategoryTabSnapshot: (state, action: PayloadAction<Record<string, any>>) => {
      // const clone = cloneDeep(state.formCategoryTabSnapshot);
      // updateObjByChainKeys(clone, action.payload);
      state.formCategoryTabSnapshot = action.payload;
    },

    // 更新CategoryDetails
    updateCategoryDetailsSnapshot: (state, action: PayloadAction<Record<string, any>>) => {
      const { tabIndex, categoryDetails } = action.payload;
      state.formCategoryTabSnapshot[tabIndex].categoryDetails = [...categoryDetails];
    },

    updateCategoryTabQuaDetails: (state, action: PayloadAction<Record<string, any>>) => {
      const { tabIndex, quaDetails } = action.payload;
      state.formCategoryTabSnapshot[tabIndex].quaDetails = quaDetails;
    },
    clearCategoryTabSnapshot: (state) => {
      state.formCategoryTabSnapshot = [];
    },

    initBrandInfo: (state) => {
      state.formBrandSnapshot = {
        brandInfos: [
          {
            uuid: v4(),
          },
        ],
      };
    },

    clearBrandInfo: (state) => {
      state.formBrandSnapshot = {
        brandInfos: [],
      };
    },

    changeCategoryIds: (state) => {
      state.categoryChangeIdsSnapshot += 1;
    },

    initOldFormSnapshot: (state, action: PayloadAction<DeepPartial<QQShopDetail>>) => {
      state.oldFormSnapshot = action.payload;
    },

    initOldBrandFormSnapshot: (state, action: PayloadAction<DeepPartial<QQShopBrandInfo['brandInfos']>>) => {
      state.oldFormBrandSnapshot.brandInfos = action.payload;
    },

    initOldFormCategorySnapshot: (state, action: PayloadAction<DeepPartial<QQShopDetail['categoryDetail']>>) => {
      state.oldFormCategorySnapshot = action.payload;
    },
  },
});

// ================================= 店铺详情快照 =================================
// 链式更新快照的属性
export const updateSnapshot = (data: Record<string, any>) => (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.updateSnapshot(data));
};

// 全量写快照，用于表单从服务端更新
export const writeSnapshot = (data: DeepPartial<QQShopDetail>) => (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.writeSnapshot(data));
};

export const deleteSnapShotByKeyChains = (keys: string) => (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.deleteSnapShotKeys(keys));
};

export const clearSnapshot = (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.reset());
};

// ================================= 品牌快照 =================================
// 链式更新品牌快照的属性
export const updateBrandSnapshot = (data: Record<string, any>) => (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.updateBrandSnapshot(data));
};

export const updateCategorySnapshot =
  (data: DeepPartial<QQShopDetail['categoryDetail']['categoryDetails']>) => (dispatch: AppDispatch) => {
    dispatch(settleV2Slice.actions.updateCategorySnapshot(data));
  };

export const delCategorySnapshot =
  (data: DeepPartial<QQShopDetail['categoryDetail']['categoryDetails']>) => (dispatch: AppDispatch) => {
    dispatch(settleV2Slice.actions.delCategorySnapshot(data));
  };

export const clearCategorySnapshot = () => (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.clearCategorySnapshot());
};

export const addBrandInfo = (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.addBrandInfo());
};

// 全量写品牌快照
export const updateBrandInfo = (data: DeepPartial<QQShopBrandInfo>) => (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.updateBrandInfo(data));
};

export const removeBrandInfo = (index: number) => (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.removeBrandInfo(index));
};

export const initBrandInfo = (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.initBrandInfo());
};

export const clearBrandInfo = (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.clearBrandInfo());
};

// ================================= 类目信息快照 =================================
export const changeCategoryIds = () => (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.changeCategoryIds());
};

// ================================= 资质类目信息快照 =================================
export const removeCategoryTabSnapshot = (index: number) => (dispatch: AppDispatch) => {
  // 删除资质信息tab
  dispatch(settleV2Slice.actions.removeCategoryTabSnapshot(index));
};
export const updateCategoryTabSnapshot =
  (tabDetails: QQShopDetail['categoryDetail']['tabDetails']) => (dispatch: AppDispatch) => {
    dispatch(settleV2Slice.actions.updateCategoryTabSnapshot(tabDetails));
  };
export const updateCategoryTabQuaDetails =
  (obj: { tabIndex: number; quaDetails: QQShopDetail['categoryDetail']['tabDetails'] }) => (dispatch: AppDispatch) => {
    // 更新的资质许可证
    dispatch(settleV2Slice.actions.updateCategoryTabQuaDetails(obj));
  };
export const clearCategoryTabSnapshot = () => (dispatch: AppDispatch) => {
  // 清掉资质许可证
  dispatch(settleV2Slice.actions.clearCategoryTabSnapshot());
};
export const updateCategoryDetailsSnapshot =
  (obj: { tabIndex: number; categoryDetails: QQShopDetail['categoryDetail']['categoryDetails'] }) =>
  (dispatch: AppDispatch) => {
    // 清掉资质许可证
    dispatch(settleV2Slice.actions.updateCategoryDetailsSnapshot(obj));
  };
// ================================= 旧的类目id快照 =================================xin
export const initOldFormCategorySnapshot = (data: QQShopDetail['categoryDetail']) => (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.initOldFormCategorySnapshot(data));
};

// ================================= 旧资质类目快照 =================================
export const initOldFormSnapshot = (data: DeepPartial<QQShopDetail>) => (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.initOldFormSnapshot(data));
};

// ================================= 旧品牌快照 =================================
export const initOldBrandFormSnapshot =
  (data: DeepPartial<QQShopBrandInfo['brandInfos']>) => (dispatch: AppDispatch) => {
    dispatch(settleV2Slice.actions.initOldBrandFormSnapshot(data));
  };

export const updateBrandUpdated = (data: boolean) => (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.updateBrandUpdated(data));
};

export const updateDetailsUpdated = (data: boolean) => (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.updateDetailsUpdated(data));
};

export const updateNoDraft = (data: boolean) => (dispatch: AppDispatch) => {
  dispatch(settleV2Slice.actions.updateNoDraft(data));
};

export default settleV2Slice.reducer;
