/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { REVIEWSTATUS } from 'modules/global';
import { MessagePlugin } from 'tdesign-react';
import { checkShopStatus } from 'utils/login';
import { Cgi } from '../../configs/settle';
import request from '../../utils/request';
import { RootState } from '../store';

const namespace = 'settle';
export enum SHOPTYPE {
  SHOP_UNKNOWN = 0,
  SHOP_ORDINARY = 1, // 普通店
  SHOP_FRANCHISE = 2, // 专营店
  SHOP_SPECIALTY = 3, // 专卖店
  SHOP_FLAGSGHIP = 4, // 旗舰店
}
export enum BRANDTYPE {
  BRAND_UNKNOWN = 0,
  BRAND_OWN = 1, // 自营品牌
  BRAND_AUTHORIZED = 2, // 授权品牌
}
export enum ACCOUNTTYPE {
  ACCOUNT_UNKNOWN = 0,
  ACCOUNT_PUBLIC = 1, // 对公账户
  ACCOUNT_PRIVATE = 2, // 对私账户
}
export enum PUBLICACCOUNTTYPE {
  PUBLIC_ACCOUNT_UNKNOWN = 0,
  PUBLIC_ACCOUNT_BASE = 1, // 基本户
  PUBLIC_ACCOUNT_ORDINARY = 2, // 一般户
}
export enum REGISTRATIONTYPE {
  REG_UNKNOWN = 0,
  REG_PERSIONAL = 1, // 个人店
  REG_ENTERPRISE = 2, // 企业店
}
export enum CARDTYPE {
  CARD_UNKNOWN = 0,
  CARD_HAND = 1, // 手持身份证
  CARD_DOUBLE = 2, // 双面+公司盖章
}
export enum LEGALMANAGER {
  LEGAL = 1, // 法人
  NOTLEGAL = 0, // 非法人
}
export enum FORMMODE {
  EDIT = 1, // 编辑
  FILL = 2, // (入驻)填写
  ADD = 3, // 新增
}
export interface ITimeInfo {
  begin_time: number;
  end_time: number;
  is_longterm_valid: number;
}
export interface IdCardInfo {
  card_type: CARDTYPE;
  id_card: string;
  id_card_time: ITimeInfo;
  head_url: string;
  country_url: string;
  people_url: string;
}
export interface IContactInfo {
  phone_num: string;
  email: string;
  wechat: string;
  qq: string;
}
export interface IShopReg {
  shop_id: string;
  registration_type: REGISTRATIONTYPE;
  review_status?: number;
  license_info: {
    company_name: string;
    social_id: string;
    bussiness_license_url: string;
    license_time: ITimeInfo;
    company_loc: string;
  };
  user_info: {
    user_name: string;
    id_card_info: IdCardInfo;
    contact_info: IContactInfo;
    is_legal_manager: LEGALMANAGER;
    customer_phone: string;
  };
}
export interface IBankInfo {
  account_id: string;
  open_account_name: string;
  bank_name: string;
  bank_province: string;
  bank_city: string;
  bank_branch: string;
  bank_certificate: string;
}
export const defShopReg = {
  shop_id: '',
  registration_type: REGISTRATIONTYPE.REG_ENTERPRISE,
  license_info: {
    company_name: '',
    social_id: '',
    bussiness_license_url: '',
    license_time: { begin_time: 0, end_time: 0, is_longterm_valid: 0 },
    company_loc: '',
    tax_certificate_url: '',
    tax_expire_time: 0,
    tax_num: 0,
  },
  user_info: {
    user_name: '',
    id_card_info: {
      card_type: 1,
      id_card: '',
      id_card_time: { begin_time: 0, end_time: 0, is_longterm_valid: 0 },
      head_url: '',
      country_url: '',
      people_url: '',
    },
    contact_info: {
      phone_num: '',
      email: '',
      wechat: '',
      qq: '',
    },
    is_legal_manager: 0,
    customer_phone: '',
  },
};
export interface IBaseInfo {
  shop_id: string;
  shop_status: number;
  shop_type: SHOPTYPE;
  shop_name: string;
  shop_reg: string;
  shop_brand: string;
  shop_category: string;
  shop_name_info?: { brand: string; enterprise: string; category: string; shop: string };
  shop_logo: string;
  shop_in_tag: number;
  manage_info: {
    user_name: string;
    is_legal_manager: LEGALMANAGER;
    customer_phone: string;
    id_card_info: IdCardInfo;
    contact_info: IContactInfo;
  };
  review_status?: number;
}
export const defBaseInfo = {
  shop_id: '',
  shop_status: 0,
  shop_type: 1,
  shop_name: '',
  shop_name_info: { brand: '', enterprise: '', category: '', shop: '' },
  shop_logo: '',
  shop_brand: '',
  shop_category: '',
  shop_reg: '',
  shop_in_tag: 0,
  manage_info: {
    user_name: '',
    is_legal_manager: 0,
    customer_phone: '',
    id_card_info: {
      id_card_time: { begin_time: 0, end_time: 0, is_longterm_valid: 0 },
      head_url: '',
      country_url: '',
      people_url: '',
      id_card: '',
      card_type: 1,
    },
    contact_info: { phone_num: '', email: '', wechat: '', qq: '' },
  },
};
export interface IAccountInfo {
  shop_id: string;
  account_type: ACCOUNTTYPE;
  public_account_type: PUBLICACCOUNTTYPE;
  bank_info: IBankInfo;
  review_status?: number;
}
export const defAccountInfo = {
  shop_id: '',
  account_type: 1,
  public_account_type: 1,
  bank_info: {
    account_id: '',
    open_account_name: '',
    bank_name: '',
    bank_province: '',
    bank_city: '',
    bank_branch: '',
    bank_certificate: '',
  },
};
export interface IBrandItem {
  brand_id: string;
  brand_type: number;
  shop_id: string;
  registration_url: string;
  registration_owner_name: string;
  brand_cn_name: string;
  brand_eng_name: string;
  registration_id: string;
  registration_time: ITimeInfo;
  auth_time: ITimeInfo;
  authorization_infos: { auth_level: number; authorization_url: string }[];
  brand_category_infos: { category_id: string; category_name: string }[];
  create_time: number;
  update_time: number;
  review_status: REVIEWSTATUS;
}
export interface IQualificationDetailItem {
  id: string;
  name: string;
  qua_certificate_url: string;
  qua_time: ITimeInfo;
}
export interface IQualificationDetail {
  qua_details: IQualificationDetailItem[];
}
export interface ICategoryItem {
  id: string;
  name: string;
  level: number;
  parent_id: string;
  review_status: number;
  earnest_money: number;
  tech_service_rate: number;
  is_specify_allow: number;
  qua_detail_group: IQualificationDetail[];
}
export interface IShopDetail {
  base_info: IBaseInfo;
  account_info: IAccountInfo;
  shop_registration: IShopReg;
  category_detail: { category_details: ICategoryItem[] };
}
export const defShopDetail: IShopDetail = {
  base_info: {
    shop_id: '',
    shop_status: 0,
    shop_type: 1,
    shop_name: '',
    shop_name_info: { brand: '', enterprise: '', category: '', shop: '' },
    shop_logo: '',
    shop_brand: '',
    shop_category: '',
    shop_reg: '',
    shop_in_tag: 0,
    manage_info: {
      user_name: '',
      is_legal_manager: 0,
      customer_phone: '',
      id_card_info: {
        id_card_time: { begin_time: 0, end_time: 0, is_longterm_valid: 0 },
        head_url: '',
        country_url: '',
        people_url: '',
        id_card: '',
        card_type: 1,
      },
      contact_info: { phone_num: '', email: '', wechat: '', qq: '' },
    },
  },
  account_info: {
    shop_id: '',
    account_type: 1,
    public_account_type: 1,
    bank_info: {
      account_id: '',
      open_account_name: '',
      bank_name: '',
      bank_province: '',
      bank_city: '',
      bank_branch: '',
      bank_certificate: '',
    },
  },
  category_detail: {
    category_details: [],
  },
  shop_registration: {
    shop_id: '',
    registration_type: REGISTRATIONTYPE.REG_ENTERPRISE,
    license_info: {
      company_name: '',
      social_id: '',
      bussiness_license_url: '',
      license_time: { begin_time: 0, end_time: 0, is_longterm_valid: 0 },
      company_loc: '',
    },
    user_info: {
      user_name: '',
      id_card_info: {
        card_type: 1,
        id_card: '',
        id_card_time: { begin_time: 0, end_time: 0, is_longterm_valid: 0 },
        head_url: '',
        country_url: '',
        people_url: '',
      },
      contact_info: {
        phone_num: '',
        email: '',
        wechat: '',
        qq: '',
      },
      is_legal_manager: 0,
      customer_phone: '',
    },
  },
};
export const defBrandItem: IBrandItem = {
  brand_id: '',
  brand_type: 1,
  shop_id: '',
  registration_url: '',
  registration_owner_name: '',
  brand_cn_name: '',
  brand_eng_name: '',
  registration_id: '',
  registration_time: { begin_time: 0, end_time: 0, is_longterm_valid: 0 },
  auth_time: { begin_time: 0, end_time: 0, is_longterm_valid: 0 },
  authorization_infos: [{ auth_level: 1, authorization_url: '' }],
  brand_category_infos: [],
  create_time: 0,
  update_time: 0,
  review_status: 0,
};
export interface IBrandInfo {
  brand_infos: IBrandItem[];
}
export interface IRejectReason {
  key: string;
  value: string;
}
export interface IShop {
  shop_detail: IShopDetail;
  brand_info: IBrandInfo;
  reject_info: IRejectReason[];
}
export const emptyShop: IShop = {
  shop_detail: {
    base_info: {
      shop_id: '',
      shop_status: 0,
      shop_type: 1,
      shop_name: '',
      shop_name_info: { brand: '', enterprise: '', category: '', shop: '' },
      shop_logo: '',
      shop_brand: '',
      shop_category: '',
      shop_reg: '',
      shop_in_tag: 0,
      manage_info: {
        user_name: '',
        is_legal_manager: 0,
        customer_phone: '',
        id_card_info: {
          id_card_time: { begin_time: 0, end_time: 0, is_longterm_valid: 0 },
          head_url: '',
          country_url: '',
          people_url: '',
          id_card: '',
          card_type: 1,
        },
        contact_info: { phone_num: '', email: '', wechat: '', qq: '' },
      },
    },
    account_info: {
      shop_id: '',
      account_type: 1,
      public_account_type: 1,
      bank_info: {
        account_id: '',
        open_account_name: '',
        bank_name: '',
        bank_province: '',
        bank_city: '',
        bank_branch: '',
        bank_certificate: '',
      },
    },
    category_detail: {
      category_details: [],
    },
    shop_registration: {
      shop_id: '',
      registration_type: REGISTRATIONTYPE.REG_ENTERPRISE,
      license_info: {
        company_name: '',
        social_id: '',
        bussiness_license_url: '',
        license_time: { begin_time: 0, end_time: 0, is_longterm_valid: 0 },
        company_loc: '',
      },
      user_info: {
        user_name: '',
        id_card_info: {
          card_type: 1,
          id_card: '',
          id_card_time: { begin_time: 0, end_time: 0, is_longterm_valid: 0 },
          head_url: '',
          country_url: '',
          people_url: '',
        },
        contact_info: {
          phone_num: '',
          email: '',
          wechat: '',
          qq: '',
        },
        is_legal_manager: 0,
        customer_phone: '',
      },
    },
  },
  brand_info: {
    brand_infos: [
      {
        brand_id: '',
        brand_type: 1,
        shop_id: '',
        registration_url: '',
        registration_owner_name: '',
        brand_cn_name: '',
        brand_eng_name: '',
        registration_id: '',
        registration_time: { begin_time: 0, end_time: 0, is_longterm_valid: 0 },
        auth_time: { begin_time: 0, end_time: 0, is_longterm_valid: 0 },
        authorization_infos: [{ auth_level: 1, authorization_url: '' }],
        brand_category_infos: [],
        create_time: 0,
        update_time: 0,
        review_status: 0,
      },
    ],
  },
  reject_info: [],
};
export const mockShop = {
  shop_detail: {
    base_info: {
      shop_id: '123',
      shop_status: 1,
      shop_type: 1,
      shop_name: '商店名称',
      shop_brand: '55555',
      shop_category: '',
      shop_reg: '454332',
      shop_name_info: { brand: '腾讯', enterprise: '企业', category: '数码', shop: 'Clay' },
      shop_logo: '17602912420',
      shop_in_tag: 1,
      manage_info: {
        user_name: 'clayzhang',
        is_legal_manager: 1,
        customer_phone: '',
        id_card_info: {
          id_card_time: { begin_time: 1656100982, end_time: 1656404982, is_longterm_valid: 0 },
          head_url: '17602912420-shop_logo-1658470725135.png',
          country_url: '17602912420-shop_logo-1658470725135.png',
          people_url: '17602912420-shop_logo-1658470725135.png',
          id_card: '422126199502037533',
          card_type: 1,
        },
        contact_info: { phone_num: '17602912420', email: 'ovenzeze@qq.com', wechat: 'isakeem', qq: '534734328' },
      },
    },
    account_info: {
      shop_id: '',
      account_type: 1,
      public_account_type: 1,
      bank_info: {
        account_id: '12345',
        open_account_name: 'clayzhang',
        bank_name: '深圳市',
        bank_province: '深圳市',
        // bank_city: '广州市',
        bank_branch: '广州市',
        bank_certificate: '17602912420-shop_logo-1658470725135.png',
      },
    },
    category_detail: {
      category_details: [
        {
          id: '10009',
          name: '食品饮料-零食坚果-饼干-夹心饼干',
          level: 3,
          parent_id: '1',
          review_status: 1,
          earnest_money: 200,
          tech_service_rate: 8,
          is_specify_allow: 0,
          qua_detail_group: [],
        },
        {
          id: '10011',
          name: '食品饮料-零食坚果-饼干-苏打饼干',
          level: 3,
          parent_id: '1',
          review_status: 1,
          earnest_money: 200,
          tech_service_rate: 8,
          is_specify_allow: 0,
          qua_detail_group: [],
        },
      ],
    },
    shop_registration: {
      shop_id: '',
      registration_type: REGISTRATIONTYPE.REG_ENTERPRISE,
      license_info: {
        company_name: '腾讯科技有限公司',
        social_id: '123',
        bussiness_license_url: '17602912420-shop_logo-1658470725135.png',
        license_time: { begin_time: 1656100982, end_time: 1656404982, is_longterm_valid: 0 },
        company_loc: '腾讯总部大厦',
      },
      user_info: {
        user_name: '郑文俊',
        id_card_info: {
          card_type: CARDTYPE.CARD_HAND,
          id_card: '422126199502037333',
          id_card_time: { begin_time: 1656100982, end_time: 1656404982, is_longterm_valid: 0 },
          head_url: '17602912420-shop_logo-1658470725135.png',
          country_url: '17602912420-shop_logo-1658470725135.png',
          people_url: '17602912420-shop_logo-1658470725135.png',
        },
        contact_info: {
          phone_num: '17602912420',
          email: 'ovenzeze@qq.com',
          wechat: 'isakeem',
          qq: '534734328',
        },
        is_legal_manager: 1,
        customer_phone: '17602912420',
      },
    },
  },
  brand_info: {
    brand_infos: [
      {
        brand_id: '',
        brand_type: 1,
        shop_id: '',
        registration_url: '17602912420-businesslicense-1658369330246.png',
        registration_owner_name: 'clayzhang',
        brand_cn_name: 'clayzhang',
        brand_eng_name: '张镇',
        registration_id: '6666666',
        registration_time: { begin_time: 1656100982, end_time: 1656404982, is_longterm_valid: 0 },
        auth_time: { begin_time: 1656100982, end_time: 1656100982, is_longterm_valid: 0 },
        authorization_infos: [{ auth_level: 1, authorization_url: '17602912420-businesslicense-1658369330246.png' }],
        brand_category_infos: [],
        create_time: 0,
        update_time: 0,
        review_status: 0,
      },
    ],
  },
  reject_info: [],
};
const shopStatus = 0;
const shop = shopStatus === 0 ? emptyShop : mockShop;

const initialState = {
  cur_step: 0, // 当前步骤
  add_loading: false,
  form_mode: FORMMODE.FILL,
  ...shop,
};
const addShopAction = async (params: any) => {
  const res = { code: -1, msg: '', rsp: {} };
  const { shopDetail, brandInfo } = params;
  try {
    const data = {
      shop_detail: shopDetail,
      brand_info: brandInfo,
    };
    console.info('[addShop][req]:', data);
    const result: any = await request.post(Cgi.addShop, data);
    console.info('[addShop][res]:', result);
    res.code = result.retcode;
    res.msg = result.errmsg;
    res.rsp = result.rsp;
  } catch (err) {
    console.error('err:', err);
  }
  return res;
};

const getShopAction = async (params: any) => {
  const res = { code: -1, msg: '', rsp: {} };
  const { shopRegistration, shopDetail, brandInfo } = params;
  try {
    const data = {
      shop_registration: shopRegistration,
      shop_detail: shopDetail,
      brand_info: brandInfo,
    };
    const result: any = await request.post(Cgi.addShop, data);
    res.code = result.retcode;
    res.msg = result.errmsg;
    res.rsp = result.rsp;
  } catch (err) {
    console.error('err:', err);
  }
  return res;
};

export const addShop = createAsyncThunk(`${namespace}/addShop`, addShopAction);
export const getShop = createAsyncThunk(`${namespace}/getShop`, getShopAction);

const userSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    nextStep: (state) => {
      const { cur_step } = state;
      state.cur_step = cur_step + 1;
    },
    prevStep: (state) => {
      const { cur_step } = state;
      state.cur_step = cur_step - 1;
    },
    setStep: (state, { payload }) => {
      state.cur_step = payload;
    },
    updateRejectInfo: (state, { payload }) => {
      state.reject_info = payload;
    },
    updateFormMode: (state, { payload }) => {
      state.form_mode = payload;
    },
    updateLicenseInfo: (state, action) => {
      const { shop_detail } = state;
      const { shop_registration } = shop_detail;
      const { payload } = action;
      console.info('[updateLicenseInfo]:', payload);
      state.shop_detail.shop_registration.license_info = { ...shop_registration.license_info, ...payload };
    },
    updateUserInfo: (state, action) => {
      const { shop_detail } = state;
      const { shop_registration } = shop_detail;
      const { payload } = action;
      console.info('[updateUserInfo]:', payload);
      state.shop_detail.shop_registration.user_info = { ...shop_registration.user_info, ...payload };
    },
    updateCategoryDetail: (state, action) => {
      const { shop_detail } = state;
      const { payload } = action;
      console.info('[updateCategoryDetail]:', payload);
      // eslint-disable-next-line max-len
      state.shop_detail.category_detail.category_details = payload;
    },
    updateShopBaseInfo: (state, action) => {
      const { shop_detail } = state;
      const { payload } = action;
      state.shop_detail.base_info = { ...shop_detail.base_info, ...payload };
    },
    updateManageInfo: (state, action) => {
      const { shop_detail } = state;
      const { base_info } = shop_detail;
      const { payload } = action;
      state.shop_detail.base_info.manage_info = { ...base_info.manage_info, ...payload };
    },
    updateAccountInfo: (state, action) => {
      const { shop_detail } = state;
      const { payload } = action;
      state.shop_detail.account_info = { ...shop_detail.account_info, ...payload };
    },
    updateBankInfo: (state, action) => {
      const { shop_detail } = state;
      const { account_info } = shop_detail;
      const { payload } = action;
      console.info('[updateBankInfo]:', payload);
      state.shop_detail.account_info.bank_info = { ...account_info.bank_info, ...payload };
    },
    updateRegType: (state, action) => {
      const { payload } = action;
      state.shop_detail.shop_registration.registration_type = payload;
    },
    updateShopRegistration: (state, { payload }) => {
      const { shop_detail } = state;
      const { shop_registration } = shop_detail;
      state.shop_detail.shop_registration = { ...shop_registration, ...payload };
    },
    updateShopDetail: (state, { payload }) => {
      state.shop_detail = { ...state.shop_detail, ...payload };
    },
    updateBrandInfo: (state, { payload }) => {
      state.brand_info = { ...state.brand_info, ...payload };
    },
    addBrandItem: (state) => {
      state.brand_info.brand_infos.push(defBrandItem);
    },
    updateBrandItem: (state, { payload }) => {
      const { index, item } = payload;
      const curItem = state.brand_info.brand_infos[index];
      if (curItem) {
        state.brand_info.brand_infos[index] = { ...curItem, ...item };
      } else {
        console.error('[updateBrandItem][emptyItem]', 'index:', index);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addShop.pending, (state) => {
      state.add_loading = true;
    });
    builder.addCase(addShop.fulfilled, (state, { payload }) => {
      const { cur_step } = state;
      state.add_loading = false;
      const { code = -1 } = payload;
      checkShopStatus();
      if (code === 0) {
        MessagePlugin.success('提交成功，请耐心等待审核');
        state.cur_step = cur_step + 1;
      } else if (code === 100) {
        MessagePlugin.info('商店已存在或在注册流程中,请检查');
      } else {
        MessagePlugin.error('商店注册失败，请稍后再试');
      }
    });
  },
});
export const selectSettle = (state: RootState) => state.settle;

export const {
  nextStep,
  prevStep,
  setStep,
  updateFormMode,
  updateRejectInfo,
  updateLicenseInfo,
  updateUserInfo,
  updateRegType,
  updateShopBaseInfo,
  updateAccountInfo,
  updateBankInfo,
  updateManageInfo,
  updateShopRegistration,
  updateShopDetail,
  updateBrandInfo,
  addBrandItem,
  updateBrandItem,
  updateCategoryDetail,
} = userSlice.actions;

export default userSlice.reducer;
