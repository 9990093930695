import { lazy } from 'react';
import { RouterType } from '../index';

const PREFIX = '/home';
const homeHeaderRoutes = {
  // 商家规则中心
  ruleCenter: 'ruleCenter',
};

const homeHeader: RouterType[] = [
  {
    path: PREFIX,
    children: [
      {
        path: homeHeaderRoutes.ruleCenter,
        Component: lazy(() => import('pages/RuleCenter/RuleCenter/index')),
        isTopLayout: true,
      },
    ],
  },
];

export default homeHeader;
