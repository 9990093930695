/**
 * @file 一番赏盲盒路由
 */
import { lazy } from 'react';
import type { RouterType } from 'types';

const yiFanBox: RouterType[] = [
  {
    // 新增一番赏盲盒
    path: '/op/yifan/create',
    Component: lazy(() => import('opPages/YiFanBox/Edit')),
    isFullPage: true,
  },
  {
    // 编辑一番赏盲盒
    path: '/op/yifan/edit/:id',
    Component: lazy(() => import('opPages/YiFanBox/Edit')),
    isFullPage: true,
  },
  {
    // 查看一番赏盲盒
    path: '/op/yifan/detail/:id',
    Component: lazy(() => import('opPages/YiFanBox/Detail')),
    isFullPage: true,
  },
  {
    // 一番赏盲盒管理
    path: '/op/yifan/manage',
    Component: lazy(() => import('opPages/YiFanBox/Manage')),
    isFullPage: true,
  },
  {
    // 一番赏-场次管理
    path: '/op/yifan/group/:id',
    Component: lazy(() => import('opPages/YiFanBox/GroupManage')),
    isFullPage: true,
  },
];

export default yiFanBox;
