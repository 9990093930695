import once from 'lodash/once';
import { lazy } from 'react';
import { resolve } from 'utils/path';
import { RouterType } from '../index';

const PREFIX = '/shopv2';
const settleV2Routes = {
  // 店铺入驻
  settled: 'settled',
  // 显示店铺入驻结果
  shopStatus: 'shopStatus',
};

type SettleV2RoutesKeys = keyof typeof settleV2Routes;

export const getShopV2Routes = once(() => {
  const routeMap: Record<SettleV2RoutesKeys, string> = {} as any;
  Object.entries(settleV2Routes).forEach(([k, v]) => {
    routeMap[k as SettleV2RoutesKeys] = resolve(PREFIX, v);
  });
  return routeMap;
});

const settleV2: RouterType[] = [
  {
    path: PREFIX,
    // meta: { title: '商家中心', Icon: DashboardIcon },
    children: [
      {
        path: settleV2Routes.settled,
        Component: lazy(() => import('pages/ShopV2/Settled')),
        // meta: { title: '商户类型(仅测试)' },
        isTopLayout: true,
      },
      {
        path: settleV2Routes.shopStatus,
        Component: lazy(() => import('pages/ShopV2/Status')),
        // meta: { title: '商户类型(仅测试)' },
        isTopLayout: true,
      },
    ],
  },
];

export default settleV2;
