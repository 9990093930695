/**
 * @file 小店路由文件
 */
import { lazy } from 'react';
import { DesktopIcon } from 'tdesign-icons-react';
import result from './modules/result';
import shop from './modules/shop';
import product from './modules/product';
import afterSale from './modules/afterSale';
import ruleCenter from './modules/ruleCenter';
import settlement from './modules/settlement';
import order from './modules/order';
import custom from './modules/customService';
import riskControl from './modules/riskControl';
import settleV2 from './modules/shopv2';
import pdfView from './modules/pdfView';
import market from './modules/market';
import faceVerify from './modules/faceVerify';
import homeHeaderMenu from './modules/homeHeaderMenu';

/** 运营端路由 */
import opRouters from 'opRouter/index';

import type { RouterType } from 'types';

export const LOGIN_PATH = '/login';
export const HOME_PATH = '/home';
export const MATERIAL_PATH = '/material';
export const HEADER_MENU_URL_LIST = ['/home/ruleCenter'];

export type { RouterType } from 'types';

const routes: RouterType[] = [
  {
    path: LOGIN_PATH,
    Component: lazy(() => import('pages/Login')),
    isFullPage: true,
  },
  {
    path: HOME_PATH,
    meta: { title: '首页', Icon: DesktopIcon },
    Component: lazy(() => import('pages/Home')),
  },
  {
    path: '/',
    redirect: HOME_PATH,
  },
];

const otherRoutes: RouterType[] = [
  {
    path: '/403',
    Component: lazy(() => import('pages/Result/403')),
  },
  {
    path: '/500',
    Component: lazy(() => import('pages/Result/500')),
  },
  {
    path: '*',
    Component: lazy(() => import('pages/Result/404')),
  },
];

const allRoutes = [
  ...opRouters,
  ...otherRoutes,
  ...routes,
  ...order,
  ...product,
  ...settlement,
  ...afterSale,
  ...shop,
  ...ruleCenter,
  ...settleV2,
  ...custom,
  ...result,
  ...riskControl,
  ...pdfView,
  ...market,
  ...faceVerify,
  ...homeHeaderMenu,
];

export default allRoutes;
