import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { COLOR_TOKEN, TColorSeries, TColorToken, LIGHT_CHART_COLORS, DARK_CHART_COLORS } from 'configs/color';
import { RootState } from '../store';
import { version } from '../../../package.json';
import { Cgi as settleCgi } from '../../configs/settle';
import request from '../../utils/request';

const namespace = 'global';

export enum ETheme {
  light = 'light',
  dark = 'dark',
}

export enum ELayout {
  side = 1,
  top,
  mix,
  fullPage,
}
export enum BRAND_STATUS {
  STATUS_EMPTY = -1, // 未入驻
  STATUS_UNKNOWN = 0,
  STATUS_REVIEWING = 1, // 审核中
  STATUS_VALID = 2, // 生效中
  STATUS_REJECT = 3, // 审核不通过
  STATUS_EXPIRE = 4, // 失效
}
export enum REVIEWSTATUS {
  REVIEW_UNKOWN = 0,
  REVIEW_INFO_PENDIND = 1, // 资质审核中
  // REVIEW_INFO_PASS = 3, // 资质审核通过
  REVIEW_INFO_REJECT = 2, // 资质审核不通过
  REVIEW_WALLET_PENDIND = 5, // 钱包审核中
  REVIEW_WALLET_REJECT = 6, // 钱包审核不通过
  REVIEW_ALL_PASS = 3, // 审核完成
  REVIEW_OPERATION_ENDING = 4, // 入驻终止
}
export interface IGlobalState {
  loading: boolean;
  collapsed: boolean;
  setting: boolean;
  version: string;
  color: string;
  isOperating: boolean; // 是否为运营平台
  theme: ETheme;
  layout: ELayout;
  isFullPage: boolean;
  showHeader: boolean;
  showBreadcrumbs: boolean;
  showFooter: boolean;
  colorList: TColorSeries;
  chartColors: TColorToken;
  uploadSig: { authorization: string; 'x-cos-security-token': string };
  reviewStatus: REVIEWSTATUS;
  hasShop: boolean;
}

const initialState: IGlobalState = {
  loading: true,
  collapsed: window.innerWidth < 1000, // 宽度小于1000 菜单闭合
  setting: false,
  isOperating: false,
  version,
  theme: ETheme.light,
  layout: ELayout.top,
  isFullPage: false,
  color: '#0052D9',
  showHeader: true,
  showBreadcrumbs: false,
  showFooter: true,
  colorList: COLOR_TOKEN,
  chartColors: LIGHT_CHART_COLORS,
  reviewStatus: REVIEWSTATUS.REVIEW_UNKOWN,
  hasShop: false,
  uploadSig: { authorization: '', 'x-cos-security-token': '' },
};

const checkShopAction = async () => {
  const res = { code: -1, msg: '', rsp: {} };
  try {
    const result: any = await request.post(settleCgi.getShopInfo);
    // NotificationPlugin.info({
    //   title: `[调试信息]店铺信息`,
    //   content: JSON.stringify(result.rsp),
    //   duration: 30 * 1000,
    // });
    res.code = result.retcode;
    res.msg = result.errmsg;
    res.rsp = result.rsp;
  } catch (err) {
    console.error('err:', err);
  }
  return res;
};
export const checkShop = createAsyncThunk(`${namespace}/checkShopStatus`, checkShopAction);

// 创建带有命名空间的reducer
const globalSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    toggleMenu: (state, action) => {
      if (action.payload === null) {
        state.collapsed = !state.collapsed;
      } else {
        state.collapsed = !!action.payload;
      }
    },
    setOperating: (state, { payload }) => {
      state.isOperating = payload;
    },
    toggleSetting: (state) => {
      state.setting = !state.setting;
    },
    toggleShowHeader: (state) => {
      state.showHeader = !state.showHeader;
    },
    toggleShowBreadcrumbs: (state) => {
      state.showBreadcrumbs = !state.showBreadcrumbs;
    },
    toggleShowFooter: (state) => {
      state.showFooter = !state.showFooter;
    },
    switchTheme: (state, action) => {
      let finalTheme = action?.payload;
      if (!finalTheme) {
        // 跟随系统
        const media = window.matchMedia('(prefers-color-scheme:dark)');
        if (media.matches) {
          finalTheme = media.matches ? ETheme.dark : ETheme.light;
        }
      }
      // 切换 chart 颜色
      state.chartColors = finalTheme === ETheme.dark ? DARK_CHART_COLORS : LIGHT_CHART_COLORS;
      // 切换主题颜色
      state.theme = finalTheme;
      document.documentElement.setAttribute('theme-mode', finalTheme);
    },
    switchColor: (state, action) => {
      if (action?.payload) {
        state.color = action?.payload;
        document.documentElement.style.setProperty('--td-brand-color-8', action?.payload);
      }
    },
    switchLayout: (state, action) => {
      if (action?.payload) {
        state.layout = action?.payload;
      }
    },
    switchFullPage: (state, action) => {
      state.isFullPage = !!action?.payload;
    },
    updateUploadSig: (state, action) => {
      const { payload } = action;
      const { sig = '', token = '' } = payload;
      state.uploadSig = { authorization: sig, 'x-cos-security-token': token };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkShop.pending, () => { });
    builder.addCase(checkShop.fulfilled, (state, { payload }) => {
      const { code = -1, rsp = {} } = payload;
      const { base_info: baseInfo = {} } = (rsp || {}) as any;
      const { shop_review_status: reviewStatus = -1 } = baseInfo;
      state.reviewStatus = reviewStatus;
      state.hasShop = code === 33001;
    });
  },
});

export const selectGlobal = (state: RootState) => state.global;

export const {
  toggleMenu,
  toggleSetting,
  toggleShowHeader,
  toggleShowBreadcrumbs,
  toggleShowFooter,
  switchTheme,
  switchColor,
  switchLayout,
  switchFullPage,
  updateUploadSig,
  setOperating,
} = globalSlice.actions;

export default globalSlice.reducer;
