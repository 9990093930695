/**
 * @file 盲盒内部路由
 */
import { lazy } from 'react';
import type { RouterType } from 'types';

const mysterProfit: RouterType[] = [
  {
    // 盲盒保盈利设置
    path: '/op/mysteryProfit/profit',
    Component: lazy(() => import('opPages/MysteryProfit/Profit')),
    isFullPage: true,
  },
];

export default mysterProfit;
