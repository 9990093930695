import { isPlainObject } from 'lodash';
import isUndefined from 'lodash/isUndefined';
import merge from 'lodash/merge';
import update from 'lodash/update';
import cookie from './cookie';

const XY_PROJECT = 'xy-project-id';
const XINGHUAN_ID = 'qqecom';
const XINGHUANG_DOMAIN = ['xy.woa.com', 'xinghuan.woa.com'];

// 链式更新对象属性
export const updateObjByChainKeys = (source: Record<string, any>, patch: Record<string, any>) => {
  Object.entries(patch).forEach(([key, val]) => {
    try {
      update(source, key, (prevVal) => {
        if (isPlainObject(prevVal) && isPlainObject(val)) {
          const result = merge({}, prevVal, val);
          return result;
        }
        if (Array.isArray(val)) {
          return [...val];
        }
        if (isPlainObject(val)) {
          return {
            ...val,
          };
        }
        return val;
      });
    } catch (e) {
      console.error('[updateObjByChainKeys]: ', key, val);
    }
  });
};

export const isNotDef = isUndefined;

export const storage = {
  setItem: (key: string, value: string) => {
    localStorage.setItem(key, value);
  },
  getItem: (key: string) => localStorage.getItem(key),
  removeItem: (key: string) => {
    localStorage.removeItem(key);
  },
  setJSON: (key: string, value: Object) => {
    try {
      storage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error('[storage.setJSON ERR]: ', e);
    }
  },
  getJSON: (key: string) => {
    try {
      const localStr = storage.getItem(key);
      if (localStr === null) {
        return null;
      }
      return JSON.parse(localStr);
    } catch (e) {
      return null;
    }
  },
};

/** 根据登录态判断是否为星环 */
export const isXingHuan = () => {
  const xyAppId = cookie.get(XY_PROJECT);
  // 兼容无界容器
  return xyAppId === XINGHUAN_ID || XINGHUANG_DOMAIN.includes(parent.location.host);
};
