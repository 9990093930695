import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { BrandCategoryInfo } from 'services/shop';
import { SHOPTYPE, REGISTRATIONTYPE } from 'modules/settle/index';
import { CategoryTreeNode } from 'services/category';

const namespace = 'loginMaterial';

export interface IMaterialForm {
  gender: number;
  shopType: number;
  category?: BrandCategoryInfo[];
}

const initialState: {
  materialForm: IMaterialForm;
  cateTreeMap: Record<string, CategoryTreeNode>;
} = {
  materialForm: {
    gender: REGISTRATIONTYPE.REG_ENTERPRISE,
    shopType: SHOPTYPE.SHOP_ORDINARY,
    category: [],
  },
  cateTreeMap: {},
};
const loginMaterialSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    initialMaterialForm: (state, { payload }) => {
      state.materialForm = payload;
    },
    updateMaterialForm: (state, { payload }) => {
      state.materialForm = {
        ...state.materialForm,
        ...payload,
      };
    },
    initialCateTreeMap: (state, { payload }) => {
      state.cateTreeMap = payload;
    },
  },
});

export const { initialMaterialForm, updateMaterialForm, initialCateTreeMap } = loginMaterialSlice.actions;

export default loginMaterialSlice.reducer;

export const selectLoginMaterial = (state: RootState) => state.loginMaterial;
