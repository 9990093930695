import { lazy } from 'react';
import type { RouterType } from 'types';

const biddingDelivery: RouterType[] = [
  {
    // 投放-竞价投放
    path: '/op/biddingDelivery/manage',
    Component: lazy(() => import('opPages/DeliveryPlan/BiddingDelivery')),
    isFullPage: true,
  },
  {
    // 投放-新增编辑复制竞价投放
    path: '/op/biddingDelivery/edit',
    Component: lazy(() => import('opPages/DeliveryPlan/NewDeliveryPlan')),
    isFullPage: true,
  },
];

export default biddingDelivery;
