import { useAppSelector } from 'modules/store';
import { useMemo } from 'react';
import { IS_ALLOWED, RoleInfo, ROLE_ID } from 'services/account';

const useRoleInfo = () => useAppSelector((state) => state.account.roleInfo);

/** 是否有商品权限 */
export const hasProductRights = (roleInfo?: RoleInfo) =>
  roleInfo?.rightsInfo.goodsManageRights?.isAllowed === IS_ALLOWED.yes;
/** 是否有商品权限 */
export const useHasProductRights = () => {
  const role = useRoleInfo();

  const hasRight = useMemo(() => hasProductRights(role), [role]);
  return hasRight;
};

/** 是否有设置客服管理权限 */
export const hasSetCustomServiceManagerRights = (roleInfo?: RoleInfo) => roleInfo?.roleId === ROLE_ID.manager;
/** 是否有设置客服管理权限 */
export const useHasSetCustomServiceManagerRights = () => {
  const role = useRoleInfo();
  return useMemo(() => hasSetCustomServiceManagerRights(role), [role]);
};

/** 是否有结算中心权限 */
export const hasSettlementRights = (roleInfo?: RoleInfo) =>
  roleInfo?.rightsInfo.settlementRights?.isAllowed === IS_ALLOWED.yes;
/** 是否有结算中心权限 */
export const useHasSettlementRights = () => {
  const role = useRoleInfo();
  return useMemo(() => hasSettlementRights(role), [role]);
};

/** 是否有发货权限 */
export const hasShippingRights = (roleInfo?: RoleInfo) =>
  roleInfo?.rightsInfo.shipManageRights?.isAllowed === IS_ALLOWED.yes;
/** 是否有发货权限 */
export const useHasShippingRights = () => {
  const role = useRoleInfo();
  return useMemo(() => hasShippingRights(role), [role]);
};

/** 是否有规则中心权限 */
export const hasRuleCenterRights = (roleInfo?: RoleInfo) =>
  roleInfo?.rightsInfo.ruleCenterRights?.isAllowed === IS_ALLOWED.yes;
/** 是否有规则中心权限 */
export const useHasRuleCenterRights = () => {
  const role = useRoleInfo();
  return useMemo(() => hasRuleCenterRights(role), [role]);
};

/** 是否有风控中心权限 */
export const hasRiskControlRights = (roleInfo?: RoleInfo) =>
  roleInfo?.rightsInfo.riskRights?.isAllowed === IS_ALLOWED.yes;
/** 是否有风控中心权限 */
export const useHasRiskControlRights = () => {
  const role = useRoleInfo();
  return useMemo(() => hasRiskControlRights(role), [role]);
};

/** 是否有营销中心权限 */
export const hasMarketingRights = (roleInfo?: RoleInfo) =>
  roleInfo?.rightsInfo.marketingRights?.isAllowed === IS_ALLOWED.yes;
/** 是否有营销中心权限 */
export const useHasMarketingRights = () => {
  const role = useRoleInfo();
  return useMemo(() => hasMarketingRights(role), [role]);
};

/** 是否有店铺管理权限 */
export const hasShopManageRights = (roleInfo?: RoleInfo) =>
  roleInfo?.rightsInfo.shopManageRights?.isAllowed === IS_ALLOWED.yes;
/** 是否有店铺管理权限 */
export const useHasShopManageRights = () => {
  const role = useRoleInfo();
  return useMemo(() => hasShopManageRights(role), [role]);
};

export const hasBulkProductRights = (roleInfo?: RoleInfo) => {
  return (
    roleInfo?.rightsInfo.goodsManageRights?.isAllowed === IS_ALLOWED.yes &&
    roleInfo?.rightsInfo.bulkProductRights?.isAllowed === IS_ALLOWED.yes
  );
};
