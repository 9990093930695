/**
 * @file 款式管理
 */
import { lazy } from 'react';
import type { RouterType } from 'types';

const boxLevelDataConfig: RouterType[] = [
  {
    // 款式管理列表
    path: '/op/boxLevelData/manage',
    Component: lazy(() => import('opPages/BoxLevelDataConfig/Manage')),
    isFullPage: true,
  },
];

export default boxLevelDataConfig;
