import { MessagePlugin } from 'tdesign-react';
import { Enum } from 'utils/enum';
import request, { humpPost } from 'utils/request';
import { encode } from 'js-base64';
import { Cgi } from 'configs/settle';
import { Cgi as LoginCgi } from 'configs/login';

interface Response<T> {
  retcode: number;
  errmsg: string;
  rsp: T;
}

const post = async <T>(path: string, data: any, notice = true) => {
  try {
    const res = await humpPost<Response<T>>(
      `https://${import.meta.env.VITE_API_HOST}/trpc.ecom.qshop_account_http_svr.QShopAccountHttp${path}`,
      data,
    );
    if (res.retcode === 0) {
      if (!res.rsp) {
        throw new Error('数据异常');
      }
      return res.rsp;
    }
    throw new Error(res.errmsg || '服务异常');
  } catch (e: any) {
    if (notice) {
      MessagePlugin.error(e.message || '服务异常');
    }

    throw e;
  }
};

export enum ROLE_ID {
  unknown = '0',
  /** 客服 */
  customer = '1',
  /** 运营 */
  operation = '2',
  /** 管理员 */
  manager = '3',
}

export const roleIdEnum = new Enum({
  customer: { value: ROLE_ID.customer, label: '店铺客服' },
  operation: { value: ROLE_ID.operation, label: '店铺运营' },
  manager: { value: ROLE_ID.manager, label: '管理员' },
});

interface OpRights {
  add: number;
  delete: number;
  get: number;
  update: number;
}

export const enum IS_ALLOWED {
  yes = 1,
  no = 0,
}
export interface Rights {
  rightId: string;
  rightName: string;
  isAllowed: IS_ALLOWED;
  opRights: OpRights;
  desc: string;
}

export interface RightsInfo {
  /** 账号中心权限 */
  accountCenterRights?: Rights;
  /** 售后管理权限 */
  afterSaleManageRights?: Rights;
  /** 规则中心权限 */
  ruleCenterRights?: Rights;
  /** 店铺管理权限 */
  shopManageRights?: Rights;
  /** 客服管理权限 */
  customerManageRights?: Rights;
  /** 商品管理权限 */
  goodsManageRights?: Rights;
  /** 发货管理权限 */
  shipManageRights?: Rights;
  /** 店铺主页权限 */
  shopHomeRights?: Rights;
  /** 结算中心权限 */
  settlementRights?: Rights;
  /** 风控中心权限 */
  riskRights?: Rights;
  /** 营销中心权限 */
  marketingRights?: Rights;
  /** 批量发布权限*/
  bulkProductRights?: Rights;
}

export interface RoleInfo {
  roleId: ROLE_ID;
  roleName: string;
  roleDesc: string;
  rightsInfo: RightsInfo;
}

export const getRights = () => post<{ roleInfos: RoleInfo[] }>('/GetRights', {});

export const getUserRightsInfo = async () => {
  const res = await post<{ roleInfo: RoleInfo }>('/GetUserRightsInfo', {}, false);
  if (res.roleInfo) {
    return res;
  }
  // 兜底，防止后台返回空
  const errMsg = '获取账号配置失败';
  MessagePlugin.error(errMsg);
  throw new Error(`网络错误：${errMsg}`);
};

export const enum INVITE_STATUS {
  unknown = 0,
  /** 待接受状态 */
  pending = 1,
  /** 已经接受状态 */
  accept = 2,
  /** 拒绝状态 */
  reject = 3,
  /** 过期状态 */
  expire = 4,
}

export const enum IS_LOGIN {
  yes = 1,
  no = 0,
}

export const enum IS_MASTER {
  yes = 1,
  no = 0,
}

export interface ChildAccountInfo {
  accountId: string;
  accountName: string;
  roleInfo: RoleInfo;
  phoneNum: string;
  qq: string;
  wechat: string;
  shopId: string;
  inviteStatus: INVITE_STATUS;
  createTime: number;
  expireTime: number;
  isLogin: IS_LOGIN;
  isMaster: IS_MASTER;
}

interface ChildAccountSearchInfo {
  accountName?: string;
  phoneNum?: string;
  roleId?: ROLE_ID;
  inviteStatus?: INVITE_STATUS;
}

interface ChildAccountReq {
  pageSize: number;
  /** 分页页码， 从1开始 */
  pageIndex: number;
  searchInfo: ChildAccountSearchInfo;
}

interface ChildAccountNum {
  totalNums: number;
  /** 已接受 */
  acceptNums: number;
  /** 待接受 */
  pendingNums: number;
  /** 拒绝 */
  rejectNums: number;
  /** 过期 */
  expireNums: number;
}

interface ChildAccountRsp {
  childAccountNum: ChildAccountNum;
  pageSize: number;
  pageIndex: number;
  accountInfos: ChildAccountInfo[];
}

// 拉取子账号列表
export const getChildAccounts = (data: ChildAccountReq) => post<ChildAccountRsp>('/GetChildAccounts', data);

interface ZhichiManagersReq {
  pageSize: number;
  // 从1开始
  pageIndex: number;
}

export const enum IS_ZHICHI_MANAGER {
  yes = 1,
  no = 0,
}

export interface ZhichiManagerInfo {
  accountId: string;
  accountName: string;
  phoneNum: string;
  qq: string;
  wechat: string;
  isZhichiManager: number;
  isLogin: number;
}

interface ZhichiManagersRsp {
  totalNums: number;
  pageSize: number;
  pageIndex: number;
  currentManageName: string;
  manageInfos: ZhichiManagerInfo[];
}

// 拉取智齿管理员列表
export const getZhichiManagers = (data: ZhichiManagersReq) => post<ZhichiManagersRsp>('/GetZhichiManagers', data);

interface SetZhichiManagerReq {
  phoneNum: string;
}
// 设置智齿管理员
export const setZhichiManager = (data: SetZhichiManagerReq) => post<{}>('/SetZhichiManager', data);

export interface InviteChildAccountInfo {
  accountName: string;
  roleInfo: Partial<RoleInfo>;
  phoneNum: string;
  qq?: string;
  wechat?: string;
}

interface InviteReq {
  accountInfo: InviteChildAccountInfo;
}
// 邀请用户，子账号等
export const inviteUser = (data: InviteReq) => {
  const { origin, pathname, search } = window.location;
  return request.post(Cgi.inviteUser, {
    inviteUrl: encode(`${origin}${pathname}${search}#/shop/bindShop?type=3`),
    ...data,
  });
};

/**
 * 退出登录，主要是用来清理后台域名(ecom.xsj.qq.com, test.ecom.xsj.qq.com)下的cookie(shop_id, shop_tk等)
 */
export const logout = () => humpPost(LoginCgi.logout, {});
