import { MessagePlugin } from 'tdesign-react';
import { Enum } from 'utils/enum';
import { humpPost } from 'utils/request';
import { Cgi } from 'configs/settle';

// 总体验证状态
export enum VERIFY_STATUS {
  /** 未知 */
  UNKNOWN = 0,
  /** 人工验证中 */
  MANUAL_VERIFY_IN_PROGRESS = 1,
  /** 人工验证失败 */
  MANUAL_VERIFY_FAILED = 2,
  /** 人工验证成功 */
  MANUAL_VERIFY_OK = 3,
  /** 人脸识别失败 */
  FACE_VERIFY_FAILED = 4,
  /** 人脸识别成功 */
  FACE_VERIFY_OK = 5,
  /** 未验证 */
  MANUAL_VERIFY_NONE = 6,
}

interface Response<T> {
  retcode: number;
  errmsg: string;
  retmsg: string;
  rsp: T;
}

const post = async <T>(path: string, data: any) => {
  try {
    const res = await humpPost<Response<T>>(
      `https://${import.meta.env.VITE_API_HOST}/trpc.ecom.qqshop_cms_http_svr.QQShopCmsHttp${path}`,
      data,
    );
    if (res.retcode === 0) {
      return res.rsp;
    }
    throw new Error(`网络错误：${res.retmsg || res.errmsg || '服务异常'}`);
  } catch (e: any) {
    MessagePlugin.error(e.message || '服务异常');
    throw e;
  }
};

interface ManualVerifyReq {
  manageHoldingIdCardUrl: string;
}

export const manualVerify = (data: ManualVerifyReq) => post('/ManualVerify', data);

// 单次人脸识别的状态
export const enum FACE_VERIFY_STATUS {
  /**  未知 */
  STATUS_UNKNOWN = 0,
  /**  未验证 */
  STATUS_NONE = 1,
  /**  验证中 */
  STATUS_IN_PROGRESS = 2,
  /**  人脸识别成功 */
  STATUS_OK = 3,
  /**  人脸识别失败 */
  STATUS_FAILED = 4,
}

export const faceVerifyStatusEnum = new Enum({
  STATUS_UNKNOWN: { value: FACE_VERIFY_STATUS.STATUS_UNKNOWN, label: '待验证' },
  STATUS_NONE: { value: FACE_VERIFY_STATUS.STATUS_NONE, label: '待验证' },
  STATUS_IN_PROGRESS: { value: FACE_VERIFY_STATUS.STATUS_IN_PROGRESS, label: '验证中' },
  STATUS_OK: { value: FACE_VERIFY_STATUS.STATUS_OK, label: '验证成功' },
  STATUS_FAILED: { value: FACE_VERIFY_STATUS.STATUS_FAILED, label: '验证失败' },
});

export const getFaceVerifyResult = () => post<{ result: FACE_VERIFY_STATUS }>('/GetFaceVerifyResult', {});

interface GetVerifyStatusRsp {
  status: VERIFY_STATUS;
  // status 为 VERIFY_STATUS_MANUAL_VERIFY_FAILED = 2 时有取值
  reasons: string[];
}

export const getVerifyStatus = () => post<GetVerifyStatusRsp>('/GetVerifyStatus', {});

interface GetFaceVerifyInfoReq {
  name: string;
  idCardNumber: string;
}

interface GetFaceVerifyInfoRsp {
  tmpkey: string;
  nonce: string;
}

export const getFaceVerifyInfo = (data: GetFaceVerifyInfoReq) => post<GetFaceVerifyInfoRsp>('/GetFaceVerifyInfo', data);

const safePost = async <T>(path: string, data: any) => {
  try {
    const res = await humpPost<Response<T>>(path, data);
    if (res.retcode === 0) {
      return res.rsp;
    }
    throw new Error(res.retmsg || res.errmsg || '服务异常');
  } catch (e: any) {
    MessagePlugin.error(e.message || '服务异常');
    throw e;
  }
};

/** 获取入驻人脸识别码 */
export const getSettleFaceCode = async () => safePost<any>(Cgi.getSettleFaceCode, {});

/** 获取用户身份信息 */
export const getUserIdentity = async (data: { settleFaceCode: string }) => safePost<any>(Cgi.getUserIdentity, data);
