// 统一账号相关服务
import { Cfg } from 'configs/login';
import once from 'lodash/once';
import { humpPost } from 'utils/request';
import { getParser } from 'utils/uaParser';
import { encode } from 'js-base64';
import { storage } from 'utils';
import { MessagePlugin } from 'tdesign-react';
import { tcaptchaWall } from './captcha';
import { ErrText, ErrCode } from './err';
import cookie from 'utils/cookie';

export const post = async <T>(path: string, data: any) => {
  try {
    const res = await humpPost<T>(`https://${import.meta.env.VITE_ACCOUNT_API_HOST}/cgi-bin${path}`, data);
    return res;
  } catch (e) {
    MessagePlugin.error('网络异常');
    throw e;
  }
};

enum PLATFORM {
  UNKNOWN_PLATFORM = 0,
  ANDROID = 1,
  IOS = 2,
}

enum DEVICE_ID_TYPE {
  DEVICE_ID_TYPE_DEFAULT = 0,
  /** 国际移动设备识别码 */
  DEVICE_ID_TYPE_IMEI = 1,
  /** IOS广告标识符 */
  DEVICE_ID_TYPE_IDFA = 2,
  /**  腾讯内部设备标识 */
  DEVICE_ID_TYPE_QIMEI = 3,
  /** 通用唯一识别码 */
  DEVICE_ID_TYPE_UUID = 4,
  /** 浏览器id */
  DEVICE_ID_TYPE_GUID = 5,
}

interface ClientInfo {
  version: string;
  /** 设备型号 */
  device: string;
  platform: PLATFORM;
  /**
   * @deprecated
   * 后续调整为通过deviceId传递
   */
  UUID: string;
  /** 设备标示符类型 */
  deviceIdType: DEVICE_ID_TYPE;
  /** 设备标示符 */
  deviceId: string;
}

interface DeviceIdInfo {
  deviceIdType: DEVICE_ID_TYPE;
  deviceId: string;
}

interface SecCheckReq {
  appid: number;
  clientInfo: ClientInfo;
  /** 手机号 */
  accountId: string;
  /** 选填 */
  accountAppid?: string;
  deviceidInfo: DeviceIdInfo;
  /** 产品请求的时间戳 */
  timeStamp: number;
  encryptData?: string;
}

const getClientInfo = once((): ClientInfo => {
  const parser = getParser();
  let deviceId = storage.getItem('deviceId');
  if (!deviceId) {
    deviceId = encode(`${window.navigator.userAgent}-${Date.now()}`);
    storage.setItem('deviceId', deviceId);
  }
  // cookie.quickSet('deviceId')
  return {
    version: '1.0.0',
    device: parser.getBrowser().name || 'unknow',
    platform: PLATFORM.UNKNOWN_PLATFORM,
    UUID: '',
    deviceIdType: DEVICE_ID_TYPE.DEVICE_ID_TYPE_GUID,
    deviceId,
  };
});

interface CommonRspHeader {
  ret: number;
  reason: string;
}

enum SEC_CHECK_RESULT {
  UNKNOWN_RESULT = 0,
  /** 有风险 */
  RISKY = 1,
  /** 无风险 */
  RISK_FREE = 2,
}

interface SecCheckReply {
  header: CommonRspHeader;
  result: SEC_CHECK_RESULT;
  signature: string;
  encrypt_data: string;
}

// SecCheck 安检，判断是否需要做人机识别
export const secCheck = async (data: { phoneNum: string }) => {
  const clientInfo = getClientInfo();

  const params: SecCheckReq = {
    appid: Cfg.luAppId,
    accountId: data.phoneNum,
    clientInfo,
    deviceidInfo: {
      deviceIdType: clientInfo.deviceIdType,
      deviceId: clientInfo.deviceId,
    },
    timeStamp: Date.now(),
    encryptData: '',
  };
  const res = await post<SecCheckReply>('/phone/secCheck', params);
  if (res.header.ret === ErrCode.Succ) {
    return {
      signature: res.signature,
      risky: res.result,
    };
  }
  const msg = ErrText[res.header.ret] || '安全验证失败';
  MessagePlugin.error(msg);
  throw new Error(msg);
};

enum CAPTCHA_PROVIDER {
  UNKNOWN_PROVIDER = 0,
  TCAPTCHA = 1, // 防水墙
}

interface GetSMSCodeRequest {
  appid: number;
  phoneNumber: string;
  provider: CAPTCHA_PROVIDER;
  /** 验证码签名信息 从防水墙拿的签名  ticket := sig["ticket"]  randstr := sig["randstr"] */
  captchaSig?: {
    ticket: string;
    randstr: string;
  };
  /** 安检签名 secCheck返回 */
  secCheckSig: string;
  deviceidInfo: DeviceIdInfo;
  timeStamp: number;
  encryptData: string;
}

interface GetSMSCodeReply {
  header: CommonRspHeader;
  encryptData: string;
}

/** 获取验证码，包含二次安全校验、防水墙等逻辑 */
export const getSMSCode = async (data: { phoneNumber: string }) => {
  const { signature, risky } = await secCheck({ phoneNum: data.phoneNumber });
  let captchaSig: GetSMSCodeRequest['captchaSig'];
  if (risky === SEC_CHECK_RESULT.RISKY) {
    // 防水墙逻辑
    const tcaptchaWallRes = await tcaptchaWall();

    captchaSig = {
      ticket: tcaptchaWallRes.ticket,
      randstr: tcaptchaWallRes.randstr,
    };
  }
  const clientInfo = getClientInfo();
  const params: GetSMSCodeRequest = {
    appid: Cfg.luAppId,
    phoneNumber: data.phoneNumber,
    provider: CAPTCHA_PROVIDER.TCAPTCHA,
    captchaSig,
    secCheckSig: signature,
    deviceidInfo: {
      deviceId: clientInfo.deviceId,
      deviceIdType: clientInfo.deviceIdType,
    },
    timeStamp: Date.now(),
    encryptData: '',
  };
  const res = await post<GetSMSCodeReply>('/phone/getSMSCode', params);
  if (res.header.ret === ErrCode.Succ) {
    return res;
  }
  const msg = ErrText[res.header.ret] || '获取验证码失败';
  MessagePlugin.error(msg);
  throw new Error(msg);
};

enum LOGIN_TYPE {
  // allow_alias = true,
  UNKNOWN_TYPE = 0,
  /** QQ登录 */
  QQ = 1,
  /** 微信登录 */
  WX = 2,
  /** 手机号 */
  PH = 3,
  /** deprecated, 使用DEVICE_ID */
  GUID = 4,
  /** 游客模式使用 */
  DEVICE_ID = 4,
  /** 苹果登录  登录配置及说明 参考 https:iwiki.woa.com/pages/viewpage.action?pageId=162063029 */
  APPLE = 5,
  /** GOOGLE 登录 */
  GOOGLE = 6,
  /** meta facebook 登录 */
  FACEBOOK = 7,
  /** Twitter登录 */
  TWITTER = 8,
}

enum AUTH_TYPE {
  OAUTH = 0, // 标准oauth鉴权，如无特别说明都是此鉴权type
  OAUTH_MINI = 1, // oauth鉴权 小程序
  QQ_A2 = 2, // qq a2
  QQ_SKEY = 3, // qq skey
  PH_GW = 4, // 手机网关取号登录
}

interface LoginRequest {
  luAppid: number;
  loginType: LOGIN_TYPE;
  /** 验证码或者网关取号token等数据 */
  accountToken: string;
  clientInfo: ClientInfo;
  /**  拓展字段    */
  busiExt: Record<string, any>;
  /** 验证码登录时填写手机号 */
  accountAppid: string;
  /** 互联openid\微信openid\QQ号 等 */
  accountId: string;
  /** 鉴权类型，默认oauth */
  authType: AUTH_TYPE;
}
interface LoginReply {
  luUid: number;
  luSessionKey: string;
  validTime: number;
  userInfo: {
    /** Oauth昵称 */
    nick: string;
    /** Oauth头像 */
    head: string;
    /** 是否新用户 */
    is_new_user: boolean;
  };
  bussinessData: string;
  luRefreshKey: string;
  undefined: undefined;
  ret: number;
  msg: string;
}

interface SMSLoginParams {
  /** 手机号 */
  phone: string;
  /** 用户输入的验证码 */
  token: string;
}

export const SMSLogin = async (data: SMSLoginParams) => {
  const clientInfo = getClientInfo();

  const params: LoginRequest = {
    luAppid: Cfg.luAppId,
    loginType: LOGIN_TYPE.PH,
    accountToken: data.token,
    clientInfo,
    busiExt: {},
    accountAppid: data.phone,
    accountId: '',
    authType: AUTH_TYPE.OAUTH,
  };

  const res = await post<LoginReply>('/login/login', params);

  if (res.ret === ErrCode.Succ && res.luSessionKey && res.luUid) {
    return res;
  }
  const msg = res.ret === ErrCode.Succ ? '登录失败' : ErrText[res.ret] || '登录失败';
  MessagePlugin.error(msg);
  throw new Error(msg);
};

/** 校验验证码 */
export const originAuth = async (data: SMSLoginParams) => {
  const clientInfo = getClientInfo();

  const params: LoginRequest = {
    luAppid: Cfg.luAppId,
    loginType: LOGIN_TYPE.PH,
    accountToken: data.token,
    clientInfo,
    busiExt: {},
    accountAppid: data.phone,
    accountId: '',
    authType: AUTH_TYPE.OAUTH,
  };

  const res = await post<LoginReply>('/login/originAuth', params);

  if (res.ret === ErrCode.Succ) {
    return res;
  }
  const msg = ErrText[res.ret] || '校验失败';
  MessagePlugin.error(msg);
  throw new Error(msg);
};

/** 基础侧 */
export const SMSLogout = async () => {
  const res = await post('/login/logout', {
    luAppid: Cfg.luAppId,
  });
  if (res?.ret) {
    MessagePlugin.error(`${res?.msg || '登出失败'}，请刷新重试`);
    // 不继续执行之后的退出逻辑Logout
    throw new Error(JSON.stringify(res));
  }
};
