import React from 'react';
import miniLogo from './assets/logo-mini.png';
import logo from './assets/logo.png';

interface LogoProp extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  mini?: boolean;
}

const Logo = (props: LogoProp) => {
  const { mini, style, ...rest } = props;
  if (mini) {
    return <img {...rest} src={miniLogo} style={{ width: 26, ...style }} />;
  }
  return <img {...rest} src={logo} style={{ width: 98, ...style }} />;
};

export default Logo;
