/**
 * @file 营销中心路由文件
 */

import { lazy } from 'react';
import { NotificationIcon } from 'tdesign-icons-react';
import { RouterType } from '../index';
import { hasMarketingRights } from 'utils/rights';

const market: RouterType[] = [
  {
    path: '/market',
    meta: { title: '营销中心', Icon: NotificationIcon },
    children: [
      {
        path: 'actPlaza',
        // meta: { title: '活动广场' },
        Component: lazy(() => import('pages/Market/ActPlaza/ActList')),
        checkRole: hasMarketingRights,
        matchPaths: ['/market/actPlaza', '/market/actDetail/:id'],
      },
      {
        path: 'actDetail/:id',
        Component: lazy(() => import('pages/Market/ActPlaza/ActDetail')),
        checkRole: hasMarketingRights,
      },
      {
        path: 'commonPlan',
        Component: lazy(() => import('pages/Market/CommonPlan')),
        meta: {
          title: '普通分销计划',
        },
        matchPaths: ['/market/productList/common'],
        checkRole: hasMarketingRights,
      },
      {
        path: 'orientatePlan',
        Component: lazy(() => import('pages/Market/OrientatePlan')),
        matchPaths: ['/market/productList/orientate'],
        meta: {
          title: '定向分销计划',
        },
        checkRole: hasMarketingRights,
      },
      {
        path: 'productList/:type',
        Component: lazy(() => import('pages/Market/ProductList')),
        checkRole: hasMarketingRights,
      },
      {
        path: 'talentSquare',
        Component: lazy(() => import('pages/Market/TalentSquare')),
        meta: {
          title: '带货达人广场',
        },
        checkRole: hasMarketingRights,
      },
      {
        path: 'dataCenter',
        Component: lazy(() => import('pages/Market/DataCenter/Daily')),
        meta: {
          title: '分销数据中心',
        },
        checkRole: hasMarketingRights,
        matchPaths: ['/market/dataCenterDetails'],
      },
      {
        path: 'dataCenterDetails',
        Component: lazy(() => import('pages/Market/DataCenter/Details')),
        checkRole: hasMarketingRights,
      },
      {
        path: 'liveAct',
        meta: { title: '直播活动' },
        Component: lazy(() => import('pages/LiveAct/LiveActList')),
        checkRole: hasMarketingRights,
        matchPaths: ['/market/newLiveAct', '/market/editLiveAct/:id', '/market/liveActDetail'],
      },
      {
        path: 'newLiveAct',
        Component: lazy(() => import('pages/LiveAct/EditLiveAct')),
        checkRole: hasMarketingRights,
      },
      {
        path: 'editLiveAct/:id',
        Component: lazy(() => import('pages/LiveAct/EditLiveAct')),
        checkRole: hasMarketingRights,
      },
      {
        path: 'liveActDetail',
        Component: lazy(() => import('pages/LiveAct/LiveActDetail')),
        checkRole: hasMarketingRights,
      },
      {
        path: 'coupon',
        meta: { title: '优惠券' },
        matchPaths: ['/market/createCoupon'],
        Component: lazy(() => import('pages/Coupon/ListPage')),
        checkRole: hasMarketingRights,
      },
      {
        path: 'coupon/:type',
        matchPaths: ['/market/createCoupon'],
        Component: lazy(() => import('pages/Coupon/ListPage')),
        checkRole: hasMarketingRights,
      },
      {
        path: 'createCoupon',
        Component: lazy(() => import('pages/Coupon/CreatePage')),
        checkRole: hasMarketingRights,
      },
      {
        path: 'sampleManage',
        Component: lazy(() => import('pages/Market/SampleManage')),
        meta: {
          title: '样品管理',
        },
        checkRole: hasMarketingRights,
      },
      {
        path: 'mainPage',
        Component: lazy(() => import('pages/Market/SampleManage/SampleList/MainPage')),
        isFullPage: true,
      },
    ],
  },
];

export default market;
