import { lazy } from 'react';
import { ROLE_ID } from 'services/account';
import { ShopIcon } from 'tdesign-icons-react';
import { hasProductRights, hasBulkProductRights } from 'utils/rights';

import { RouterType } from '../index';

const product: RouterType[] = [
  {
    path: '/product',
    meta: { title: '商品管理', Icon: ShopIcon },
    children: [
      {
        path: 'productPublish',
        Component: lazy(() => import('pages/Product/ProductPublish')),
        meta: { title: '单个商品发布' },
        checkRole: hasProductRights,
      },
      {
        path: 'productBulkPublish',
        Component: lazy(() => import('pages/Product/ProductBulkPublish')),
        meta: { title: '批量商品操作' },
        checkRole: hasBulkProductRights,
      },
      {
        path: 'productList',
        Component: lazy(() => import('pages/Product/ProductList')),
        meta: { title: '商品列表' },
        checkRole: (role) => {
          if (role?.roleId === ROLE_ID.customer) {
            return true;
          }
          return hasProductRights(role);
        },
        matchPaths: ['/product/productDetail', '/product/productEdit'],
      },
      {
        path: 'productDetail',
        Component: lazy(() => import('pages/Product/ProductPublish/detail')),
        checkRole: (role) => {
          if (role?.roleId === ROLE_ID.customer) {
            return true;
          }
          return hasProductRights(role);
        },
      },
      {
        path: 'productEdit',
        Component: lazy(() => import('pages/Product/ProductPublish/edit')),
        checkRole: hasProductRights,
      },
      {
        path: 'productTest',
        Component: lazy(() => import('pages/Product/ProductTest')),
        checkRole: hasProductRights,
      },
      {
        path: 'UtilsAuthorizationPage',
        Component: lazy(() => import('pages/Product/ProductExternalPublish/authorizationPage')),
        checkRole: hasProductRights,
      },
    ],
  },
];

export default product;
