import { lazy } from 'react';
import type { RouterType } from 'types';

const kingCommission: RouterType[] = [
  // 王者分佣
  {
    // 分佣链接管理
    path: '/op/commission/manage',
    Component: lazy(() => import('opPages/KingCommission/pages/CommissionManage')),
    isFullPage: true,
  },
  {
    // 识别信息管理
    path: '/op/identify/manage',
    Component: lazy(() => import('opPages/KingCommission/pages/IdentifyInfoManage')),
    isFullPage: true,
  },
];

export default kingCommission;
