import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Area, singleAreaClass } from 'pages/Delivery/LogisticsModule/pb';
import { MessagePlugin } from 'tdesign-react';
import { RootState } from '../store';
// import { changeStatus } from 'services/cardSearch';

const namespace = 'logisticsModule';

export interface IGlobalState {
  storeAreaList: Array<singleAreaClass>;
}

const initialState: IGlobalState = {
  storeAreaList: [],
};

// 创建带有命名空间的reducer
const logisticsModuleSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setStoreAreaList: (state, action) => {
      state.storeAreaList = action.payload;
    },
  },
});

export const logisticsModuleSelc = (state: RootState) => state.logisticsModule;

export const { setStoreAreaList } = logisticsModuleSlice.actions;

export default logisticsModuleSlice.reducer;
