import { lazy } from 'react';
import { ControlPlatformIcon } from 'tdesign-icons-react';
import { hasRiskControlRights } from 'utils/rights';
import { RouterType } from '../index';

const riskControl: RouterType[] = [
  {
    path: 'riskControl',
    meta: { title: '风控中心', Icon: ControlPlatformIcon },
    Component: lazy(() => import('pages/RiskControl/Home')),
    checkRole: hasRiskControlRights,
    matchPaths: [
      '/riskControl',
      '/riskControlList',
      '/strategyEdit',
      '/strategyDetail',
    ],
  },
  {
    path: 'riskControlList',
    Component: lazy(() => import('pages/RiskControl/RiskControl')),
    checkRole: hasRiskControlRights,
  },
  {
    path: 'strategyEdit',
    Component: lazy(() => import('pages/RiskControl/StrategyEdit')),
    checkRole: hasRiskControlRights,
  },
  {
    path: 'strategyDetail',
    Component: lazy(() => import('pages/RiskControl/StrategyDeatil')),
    checkRole: hasRiskControlRights,
  },
];

export default riskControl;
