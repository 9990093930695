/**
 * @file 盲盒进阶路由
 */
import { lazy } from 'react';
import type { RouterType } from 'types';

const boxAdvance: RouterType[] = [
  {
    path: '/op/boxAdvance/create',
    Component: lazy(() => import('opPages/BoxAdvance/Edit')),
    isFullPage: true,
  },
  {
    path: '/op/boxAdvance/edit/:id',
    Component: lazy(() => import('opPages/BoxAdvance/Edit')),
    isFullPage: true,
  },
  {
    path: '/op/boxAdvance/detail/:id',
    Component: lazy(() => import('opPages/BoxAdvance/Detail')),
    isFullPage: true,
  },
  {
    path: '/op/boxAdvance/manage',
    Component: lazy(() => import('opPages/BoxAdvance/Manage')),
    isFullPage: true,
  },
  {
    path: '/op/boxAdvance/exchangeUserManage/:productId',
    Component: lazy(() => import('opPages/BoxAdvance/ExchangeUserManage')),
    isFullPage: true,
  },
  {
    path: '/op/boxAdvance/exchangeProductManage/:orderId',
    Component: lazy(() => import('opPages/BoxAdvance/ExchangeProductManage')),
    isFullPage: true,
  },
];

export default boxAdvance;
