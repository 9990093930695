import 'url-search-params-polyfill';
import axios, { AxiosRequestConfig } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { MessagePlugin } from 'tdesign-react';
import { v4 } from 'uuid';

import { Cgi, Cfg as LoginCfg } from 'configs/login';
import proxy from '../configs/host';
import aegis from './aegis';
import cookie from './cookie';
import { logoutAction } from './login';
import { getHashPath } from './path';
import { isXingHuan } from './index';
import LruMemoryCache from './lruCache';

const needThrottleApi = ['trpc.ecom.qqshop_cms_http_svr.QQShopCmsHttp/AddShop'];
const env = import.meta.env.MODE || 'development';
const API_HOST = proxy[env].API;
const TIMEOUT = 15000;
const pageId = v4();

export enum TRPC_ERROR_CODE {
  invalidSession = -10109,
  baseInvalidSession = 31015,
  /** 不存在该角色，店铺管理权移交时会出现 */
  noRole = 33500,
}

export const instance = axios.create({
  baseURL: API_HOST,
  timeout: TIMEOUT,
  withCredentials: true,
});

// skey_val是从cookies中取到的skey值
const etACSRFToken = (z: string) => {
  let hash = 5381;
  for (let i = 0, len = z.length; i < len; ++i) {
    // eslint-disable-next-line no-bitwise
    hash += (hash << 5) + z.charAt(i).charCodeAt(0);
  }
  // eslint-disable-next-line no-bitwise
  return String(hash & 0x7fffffff);
};

instance.interceptors.request.use((request) => {
  const shopTk = cookie.get('shop_tk');
  if (shopTk && request.url) {
    const [baseUrl, searchParams] = request.url.split('?');
    const u = new URLSearchParams(searchParams || '');
    u.append('g_tk', etACSRFToken(shopTk));
    const timestamp = Date.now();
    u.append('ts', String(timestamp));
    let url = baseUrl;
    url += '?';
    u.forEach((value, key) => {
      const prefix = url.endsWith('?') ? '' : '&';
      url += `${prefix}${key}=${encodeURIComponent(value)}`;
    });

    request.url = url;
  }
  return request;
});

const logout = (curCgiUrl?: string) => {
  const { path } = getHashPath();
  if (path !== '/shop/bindShop' && path !== '/login' && path !== '/market/mainPage') {
    /**
     * 任何页面打开都会触发 modules/account -> getAccountInfo 发起 Cgi.checkShop 请求
     * getAccountInfo 不判断trpcCode，即使trpcode没问题，但是返回数据不完整也会触发logout, 所以避免重复触发
     * 这里主要用于已经登录状态，调用其他接口时，发现登录失效时自动退出登录
     */
    if (curCgiUrl !== Cgi.checkShop) {
      aegis.infoAll('===后台报10109退出登录===');
      logoutAction();
    }
    throw new Error('请重新登录');
  }
};

instance.interceptors.response.use(
  // eslint-disable-next-line complexity
  (response) => {
    const { data, status, config, headers } = response;
    const trpcCode = headers['trpc-func-ret'] ? Number(headers['trpc-func-ret']) : Number(headers['trpc-ret']);
    const urlList: any = config.url?.split('/');
    const cgiName = urlList[4] || urlList[3] || urlList[2];

    aegis.infoAll('[request]:', '[cgi]-', cgiName, '[req]-', config.data, '[res]-', data, '[pageId]-', pageId);

    if (
      [TRPC_ERROR_CODE.invalidSession, TRPC_ERROR_CODE.baseInvalidSession].includes(trpcCode) ||
      [TRPC_ERROR_CODE.invalidSession, TRPC_ERROR_CODE.baseInvalidSession].includes(data?.ret) ||
      [TRPC_ERROR_CODE.invalidSession, TRPC_ERROR_CODE.baseInvalidSession].includes(data?.retcode)
    ) {
      if (cgiName !== 'Logout' || cgiName !== 'login') {
        logout(config.url);
      }
      return {};
    }

    if (status === 200) {
      if (data.retcode === TRPC_ERROR_CODE.noRole) {
        aegis.infoAll('[33500]:', '[cgi]-', cgiName, '[req]-', config.data, '[res]-', data);
        MessagePlugin.error('抱歉，您暂无使用权限', 3000);
      }
      return data;
    }
    throw response;
  },
  (err) => {
    const appId = cookie.get(`lu_${LoginCfg.luAppId}_open_appid`);

    if (!appId && !isXingHuan()) {
      aegis.infoAll('==接口catch没有openid退出登录==');
      logout();
    }
    throw err;
  },
);
export default instance;

// 初始化lru缓存
let cacheMap: any = null;
try {
  cacheMap = new LruMemoryCache();
} catch (e) {}
// 设置500毫秒过期时间
const DEFAULT_EXPIRE_DELAY = 500;

/** axios节流：把500毫秒内相同请求相同请求体合成一个，防止反复触发 */
export const humpPost = (url: string, data: { [key: string]: any }, config?: AxiosRequestConfig) => {
  try {
    const urlArr = url.split('/');
    // 白名单控制
    if (needThrottleApi.includes(`${urlArr[3]}/${urlArr[4]}`) && cacheMap) {
      const newCache = cacheMap.get(url, data);
      // 若是在ttl内遇到相同请求,直接reject
      if (newCache) {
        return new Promise((resolve, reject) => {
          reject();
        });
      }
      // 加入缓存，500毫秒后自动释放
      cacheMap.set(url, data, DEFAULT_EXPIRE_DELAY);
    }
  } catch (e) {
    return instanceHumpPost(url, data, config);
  }
  // 发出请求
  return instanceHumpPost(url, data, config);
};

const instanceHumpPost = async <T = any>(url: string, data: { [key: string]: any }, config?: AxiosRequestConfig) => {
  const res = await instance.post(url, decamelizeKeys(data), config);
  return camelizeKeys(res) as unknown as Promise<T>;
};
