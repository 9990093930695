import { lazy } from 'react';
import { ViewModuleIcon } from 'tdesign-icons-react';
import { hasRuleCenterRights } from 'utils/rights';
import { RouterType } from '../index';

const ruleCenter: RouterType[] = [
  {
    path: '/ruleCenter',
    meta: { title: '规则中心', Icon: ViewModuleIcon },
    children: [
      {
        path: 'ruleCenter',
        Component: lazy(() => import('pages/RuleCenter/RuleCenter')),
        meta: { title: '规则中心' },
        checkRole: hasRuleCenterRights,
      },
      {
        path: 'penaltyAppeal',
        Component: lazy(() => import('pages/RuleCenter/PenaltyAppealManage')),
        meta: { title: '处罚申诉管理' },
        checkRole: hasRuleCenterRights,
        matchPaths: ['/ruleCenter/penaltyDetail/:id'],
      },
      {
        // 处罚申诉详情页
        path: 'penaltyDetail/:id',
        Component: lazy(() => import('pages/RuleCenter/PenaltyDetail')),
        checkRole: hasRuleCenterRights,
      },
    ],
  },
];

export default ruleCenter;
