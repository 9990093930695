import { lazy } from 'react';
import type { RouterType } from 'types';

const auditLog: RouterType[] = [
  {
    // 审计页面日志查询
    path: '/op/auditLog/list',
    Component: lazy(() => import('opPages/AuditLog')),
    isFullPage: true,
  },
];

export default auditLog;
