import allRoutes, { RouterType } from './index';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'modules/store';

export const useHandlePathPermission = () => {
  const navigate = useNavigate();
  const roleInfo = useAppSelector((state) => state.account.roleInfo);
  const { pathname } = useLocation();

  /**
   * 获取该路由的信息
   *
   * @param pathItemArr 路由数组
   * @param routeArr 路由层级信息
   * @returns {DeepPartial<RouterType>}
   */
  const getPathRole = (pathItemArr: string[], routeArr: RouterType[]): DeepPartial<RouterType> => {
    if (!pathItemArr.length) {
      return {};
    }
    // 还有/:
    const params = pathItemArr[0].split('/:') ?? [];
    const pathObj = routeArr.find((item: any) => [`/${pathItemArr[0]}`, pathItemArr[0], params[0]].includes(item.path));
    if (!pathObj?.children) {
      return pathObj;
    }
    pathItemArr.shift();
    return getPathRole(pathItemArr, pathObj.children);
  };

  /**
   * 获取该路由该角色是否有权限
   *
   * @returns {boolean}
   */
  const handlePathPermission = () => {
    try {
      if (!pathname || !allRoutes) {
        return false;
      }

      const pathItemArr = pathname.split('/') ?? [];
      pathItemArr.shift();

      const pathRoleObj = getPathRole(pathItemArr, allRoutes);
      // 如果该路由要检查角色
      if (pathRoleObj?.checkRole) {
        const checkRoleRes = pathRoleObj?.checkRole(roleInfo);
        // 如果没有权限，则跳转到无权限页
        if (!checkRoleRes) {
          navigate('/403', { replace: true });
          return false;
        }
      }
      return true;
    } catch (e) {
      navigate('/404');
    }
  };

  return {
    handlePathPermission,
  };
};
