import { RouterType } from '../index';
import { lazy } from 'react';

const pdfView: RouterType[] = [
  {
    path: '/pdfView/:url',
    Component: lazy(() => import('pages/PdfView')),
  },
];

export default pdfView;
