/**
 * @file 模拟测算router
 */
import { lazy } from 'react';
import type { RouterType } from 'types';

const simulation: RouterType[] = [
  {
    // 模拟测算工具
    path: '/op/simulation/manage',
    Component: lazy(() => import('opPages/Simulation/Manage')),
    isFullPage: true,
  },
  {
    // 模拟测算工具
    path: '/op/simulation/create',
    Component: lazy(() => import('opPages/Simulation/Edit')),
    isFullPage: true,
  },
  {
    // 编辑任务
    path: '/op/simulation/edit/:id',
    Component: lazy(() => import('opPages/Simulation/Edit')),
    isFullPage: true,
  },
  {
    // 任务详情
    path: '/op/simulation/detail/:id',
    Component: lazy(() => import('opPages/Simulation/Detail')),
    isFullPage: true,
  },
];

export default simulation;
