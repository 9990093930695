export const resolve = (path1 = '', path2 = '') => {
  let separator = '/';
  if (path1.endsWith('/') || path2.startsWith('/')) {
    separator = '';
  }
  return `${path1}${separator}${path2}`;
};

const decodeQueryString = (query: string) => {
  const queryMap: {[key: string]: string} = {};
  query.split('&').forEach((item) => {
    const [key, value] = item.split('=');
    queryMap[key] = decodeURIComponent(value);
  });

  return queryMap;
};

export const getHashPath = () => {
  const { hash } = window.location;
  const [, pathWithQuery = ''] = hash.split('#');
  const [path, query = ''] = pathWithQuery.split('?');

  return {
    fullPath: pathWithQuery,
    path,
    queryObj: decodeQueryString(query),
  };
};
