import { lazy } from 'react';
import { ROLE_ID } from 'services/account';
import { ServerIcon } from 'tdesign-icons-react';
import { hasShippingRights } from 'utils/rights';
import { RouterType } from '../index';

const order: RouterType[] = [
  {
    path: '/order',
    meta: { title: '发货管理', Icon: ServerIcon },
    children: [
      {
        path: 'orderList',
        Component: lazy(() => import('pages/Order/OrderList')),
        meta: { title: '订单查询' },
        checkRole: (role) => {
          if (role?.roleId === ROLE_ID.customer) {
            return true;
          }
          return hasShippingRights(role);
        },
        matchPaths: ['/order/orderDetail', '/order/combinedShipment'],
      },
      {
        path: 'combinedShipment/:id',
        Component: lazy(() => import('pages/Order/CombinedShipment')),
        checkRole: (role) => {
          if (role?.roleId === ROLE_ID.customer) {
            return true;
          }
          return hasShippingRights(role);
        },
      },
      {
        path: 'orderDetail',
        Component: lazy(() => import('pages/Order/OrderDetail')),
        checkRole: (role) => {
          if (role?.roleId === ROLE_ID.customer) {
            return true;
          }
          return hasShippingRights(role);
        },
      },
      {
        path: 'deliverCenter',
        Component: lazy(() => import('pages/Order/DeliverCenter')),
        checkRole: hasShippingRights,
        meta: { title: '发货中心' },
      },
      {
        path: 'deliverCenter/:tab',
        Component: lazy(() => import('pages/Order/DeliverCenter')),
        checkRole: hasShippingRights,
      },
      {
        path: 'addressManage',
        Component: lazy(() => import('pages/Delivery/AddressManage')),
        meta: { title: '地址管理' },
        checkRole: hasShippingRights,
      },
      {
        path: 'logisticsModule',
        meta: { title: '物流模板' },
        Component: lazy(() => import('pages/Delivery/LogisticsModule/Home')),
        checkRole: hasShippingRights,
        matchPaths: ['/order/logisticsModuleUsage/:id', '/order/editLogisticsModule/:id', '/order/editLogisticsModule'],
      },
      // 物流模板使用详情
      {
        path: 'logisticsModuleUsage/:id',
        Component: lazy(() => import('pages/Delivery/LogisticsModule/ModuleUsage')),
        checkRole: hasShippingRights,
      },
      // 编辑物流模板
      {
        path: 'editLogisticsModule/:id',
        Component: lazy(() => import('pages/Delivery/LogisticsModule/EditModule')),
        checkRole: hasShippingRights,
      },
      // 创建物流模板 todo这里看下和前面合在一起
      {
        path: 'editLogisticsModule',
        Component: lazy(() => import('pages/Delivery/LogisticsModule/EditModule')),
        checkRole: hasShippingRights,
      },
      {
        path: 'elecFaceSheet',
        meta: { title: '电子面单' },
        checkRole: hasShippingRights,
        Component: lazy(() => import('pages/Order/ElecFaceSheet')),
      },
    ],
  },
];

export default order;
