import { lazy } from 'react';
import { MoneyCircleIcon } from 'tdesign-icons-react';
import { hasSettlementRights } from 'utils/rights';
import { RouterType } from '../index';

const settlement: RouterType[] = [
  {
    path: '/settlement',
    meta: {
      title: '结算中心',
      Icon: MoneyCircleIcon,
    },
    children: [
      {
        path: 'accounInfo',
        Component: lazy(() => import('pages/SettlementAccount/AccountInfo')),
        meta: {
          title: '账户信息',
        },
        checkRole: hasSettlementRights,
      },
      {
        path: 'overview',
        Component: lazy(() => import('pages/SettlementAccount/Overview')),
        meta: {
          title: '结算总览',
        },
        checkRole: hasSettlementRights,
        matchPaths: ['/settlement/overview', '/settlement/noOpenStatusError'],
      },
      {
        path: 'noOpenStatusError', // 这里实际是判断账户是否开通的兜底页， 开通则跳转到overview
        Component: lazy(() => import('pages/SettlementAccount/OpenStatusError')),
        // meta: {
        //   title: '结算总览',
        // },
        checkRole: hasSettlementRights,
        // matchPaths: [
        //   '/settlement/overview',
        // ],
      },
      {
        path: 'bill',
        Component: lazy(() => import('pages/SettlementAccount/Bill')),
        meta: {
          title: '对账单',
        },
        checkRole: hasSettlementRights,
      },
      {
        path: 'paymentSettings',
        Component: lazy(() => import('pages/SettlementAccount/PaymentSettings')),
        meta: {
          title: '支付设置',
        },
        checkRole: hasSettlementRights,
      },
    ],
  },
];

export default settlement;
