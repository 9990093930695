import { createSlice } from '@reduxjs/toolkit';
import deepSet from 'lodash/set';
import { RootState } from '../store';
import { Product, PropertyRelate, DeliveryMode, DeliveryOpportunity, DeliveryTime } from 'pages/Product/shared/types';

const namespace = 'product';

interface ErrorMap {
  0: boolean[];
  1: boolean[];
  2: boolean[];
  3: boolean[];
}

const initialState: {
  curStep: 0 | 1 | 2 | 3;
  hasChange: boolean;
  hasErrorList: ErrorMap;
  product?: Product;
  properties: PropertyRelate[];
  categoryLabel: string;
  deliveryMode: number;
} = {
  curStep: 0, // 当前步骤
  hasChange: false,
  hasErrorList: {
    0: [],
    1: [true, true],
    2: [true, true, false, true],
    3: [],
  },
  product: {
    bizType: '1',
    brand: { id: '', name: '' },
    service: {
      items: [
        {
          serviceType: 0,
          icon: '',
          title: '',
          desc: '',
        },
      ],
      shipTemplateId: '',
    },
    skus: [],
    spu: {
      shopId: '',
      brandId: '',
      spuId: '',
      title: '',
      desc: '',
      mainPics: [],
      detailPics: [],
      category: {
        firstCid: '',
        firstName: '',
        secondCid: '',
        secondName: '',
        thirdCid: '',
        groupId: '',
        groupLevel: 1,
        thirdName: '',
        fourthCid: '',
        fourthName: '',
        isLeaf: false,
      },
      price: {
        max: '0',
        min: '0',
        origin: '',
      },
      property: {
        items: [],
      },
      tradeInfo: {
        accountName: '',
        accountType: 0,
        tradeType: 0,
        deliveryMode: DeliveryMode.DELIVERY_MODE_OFF_THE_SHELF,
        deliveryOpportunity: DeliveryOpportunity.DELIVERY_AFTER_PAYMENT, // 发货时机
        deliveryTimeType: DeliveryTime.DELIVERY_OPPORTUNITY_48_HOURS, // 现货模式需要传
        deliveryDay: 0,
        presaleEndTime: 0,
        hasEndTime: false,
      },
    },
  },
  properties: [],
  categoryLabel: '暂无',
  deliveryMode: DeliveryMode.DELIVERY_MODE_OFF_THE_SHELF,
};
const productSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    initProduct: (state, { payload }) => {
      state.product = payload;
    },
    setProduct: (state, { payload }) => {
      state.product = payload;
      console.log('------------setProduct------------');
    },
    clearProduct: (state) => {
      console.log('------------clearProduct------------');
      state.product = undefined;
      state.curStep = 0;
    },
    setProperties: (state, { payload }) => {
      state.properties = payload;
      console.log('------------setProperties------------');
    },
    clearProperties: (state) => {
      console.log('------------clearProperties------------');
      state.properties = [];
    },
    setCategoryLabel: (state, { payload }) => {
      state.categoryLabel = payload;
    },
    updateProductInfo: (state, { payload }) => {
      const [path, value] = payload;
      const data = { ...state.product };
      state.product = deepSet(data, path, value) as Product;
    },
    setHasChange: (state, { payload }) => {
      state.hasChange = payload;
    },
    setStep: (state, { payload }) => {
      state.curStep = payload;
    },
    prevStep: (state) => {
      state.curStep -= 1;
    },
    nextStep: (state) => {
      state.curStep += 1;
    },
    setErrorList: (state, { payload }) => {
      const [index, hasError] = payload;
      state.hasErrorList[state.curStep][index] = hasError;
    },
    resetHasErrorList: (state) => {
      state.hasErrorList = {
        0: [],
        1: [true, true],
        2: [true, false, true, true],
        3: [],
      };
    },
    updateDeliveryMode: (state, { payload }) => {
      state.deliveryMode = payload;
    },
  },
});

export const {
  updateProductInfo,
  initProduct,
  setProduct,
  setHasChange,
  clearProduct,
  setStep,
  prevStep,
  nextStep,
  setErrorList,
  resetHasErrorList,
  setProperties,
  clearProperties,
  setCategoryLabel,
  updateDeliveryMode,
} = productSlice.actions;

export default productSlice.reducer;

export const selectProduct = (state: RootState) => state.product;
