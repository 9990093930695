import request, { humpPost } from 'utils/request';
import { Cgi } from 'configs/shopcontract';
import { MessagePlugin } from 'tdesign-react';

export interface IShopAgreementDetail {
  agreement_id: string; // 合同编号，上传的时候不需要填，后端生成
  agreement_name: string; // 合同名称
  agreement_url: string; // 合同url
  agreement_sign_status: number; // 合同签约状态 0: 待签约 1: 已签约
  current_agreement_index: string; // 合同索引编号
  is_delete: boolean;
  shop_id: string;
}

export enum signStatusEnum {
  NOT_SIGN = 0,
  SINGED = 1,
}
export enum delStatusEnum {
  NOT_DEL = 0,
  DELETED = 1,
}

interface IShopAgreementInfosRsp {
  total_nums: number;
  page_index: number;
  page_size: number;
  details: IShopAgreementDetail[];
}

interface IResult {
  errmsg: string;
  retcode: number;
  rsp: IShopAgreementInfosRsp;
}
interface IParams {
  pageSize: number;
  current: number;
}
// 分页拉取店铺合同信息
export const getContractList = async (params: IParams) => {
  const result = await request.post<null, IResult>(Cgi.GetShopAgreementInfos, {
    page_index: params.current,
    page_size: params.pageSize,
  });
  console.log('getContractList', result);

  const list = result?.rsp.details || [];
  const total = result?.rsp.total_nums || 0;
  return {
    list,
    total,
  };
};

interface IUpdateParams {
  agreement_id: string;
  agreement_sign_status: number;
}
interface IUpdateResult {
  errmsg: string;
  retcode: number;
  rsp: object;
}
// 更新合同信息
export const updateAgreementInfo = async (param: IUpdateParams) => {
  const result = await request.post<null, IUpdateResult>(Cgi.UpdateAgreementInfo, param);
  console.log('updateAgreementInfo', result);
  return result;
};

const agreUrlPrifix = `https://${import.meta.env.VITE_API_HOST}/trpc.ecom.qqshop_cms_http_svr.QQShopCmsHttp`;

// 获取合同新的cdn链接
const getPicURL = async (filename: string): Promise<string> => {
  const result: any = await humpPost(`${agreUrlPrifix}/GetPicURL`, {
    filename,
  });
  if (result.retcode === 0) {
    return result.rsp.picUrl;
  }
  return '';
};

export const getShopAgreementInfo = async (agreementId: string) => {
  const result = await humpPost(`${agreUrlPrifix}/GetShopAgreementInfo`, {
    agreementId,
  });
  if (result.retcode !== 0) {
    throw new Error(result.errmsg);
  } else {
    const { agreementUrl } = result.rsp.detail;
    // if (agreementSignStatus === signStatusEnum.SINGED) return result.rsp.detail;
    const newUrl = await getPicURL(agreementUrl);
    return {
      ...result.rsp.detail,
      agreementUrl: newUrl,
    };
  }
};

export const getSettleAgreementInfo = async (agreementId: string) => {
  const result = await humpPost(`${agreUrlPrifix}/GetShopAgreementInfo`, {
    agreementId,
  });
  if (result.retcode !== 0) {
    MessagePlugin.error(result.retmsg || result.errmsg || '获取入驻签约合同服务异常');
    return {
      agreementUrl: '',
      agreementSignStatus: signStatusEnum.SINGED,
    };
  }
  const { agreementUrl, agreementSignStatus } = result.rsp.detail;
  if (agreementSignStatus === signStatusEnum.SINGED) return result.rsp.detail;
  const newUrl = await getPicURL(agreementUrl);
  return {
    ...result.rsp.detail,
    agreementUrl: newUrl,
  };
};

export const signAggrement = async (params: { agreementId: string; agreementSignStatus: signStatusEnum }) => {
  const result = await humpPost<IResult>(`${agreUrlPrifix}/UpdateAgreementInfo`, params);
  if (result.retcode !== 0) {
    throw new Error(result.errmsg);
  }
};
