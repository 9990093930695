import { lazy } from 'react';
import type { RouterType } from 'types';

const liveProductConfig: RouterType[] = [
  {
    // 新建配置
    path: '/op/liveProduct/create',
    Component: lazy(() => import('opPages/LiveProductConfig/Create')),
    isFullPage: true,
  },

  {
    // 拉流直播配置
    path: '/op/liveProduct/manage',
    Component: lazy(() => import('opPages/LiveProductConfig/Manage')),
    isFullPage: true,
  },
];

export default liveProductConfig;
