import { lazy } from 'react';
import type { RouterType } from 'types';

const scoreProduct: RouterType[] = [
  {
    // 积分商品管理
    path: '/op/scoreProduct/manage',
    Component: lazy(() => import('opPages/ScoreProduct/Manage')),
    isFullPage: true,
  },
  {
    // 新增积分商品
    path: '/op/scoreProduct/create',
    Component: lazy(() => import('opPages/ScoreProduct/Create')),
    isFullPage: true,
  },
  {
    // 编辑积分商品
    path: '/op/scoreProduct/edit/:id',
    Component: lazy(() => import('opPages/ScoreProduct/Create')),
    isFullPage: true,
  },
];

export default scoreProduct;
