import { lazy } from 'react';
import type { RouterType } from 'types';

const crowdPack: RouterType[] = [
  {
    // 自定义号码包查询
    path: '/op/crowdPack/manage',
    Component: lazy(() => import('opPages/CrowdPack/Manage')),
    isFullPage: true,
  },
];

export default crowdPack;
