/**
 * @file q+内容加热路由配置文件
 */
import { lazy } from 'react';
import type { RouterType } from 'types';

const contentHeating: RouterType[] = [
  {
    // 订单看板
    path: '/op/contentHeating/orderManage',
    Component: lazy(() => import('opPages/ContentHeating/OrderManage')),
    isFullPage: true,
  },
  {
    // 订单详情页
    path: '/op/contentHeating/orderDetail',
    Component: lazy(() => import('opPages/ContentHeating/OrderManageDetail')),
    isFullPage: true,
  },
  {
    // 动态配置
    path: '/op/contentHeating/targetConfig',
    Component: lazy(() => import('opPages/ContentHeating/DeliveryTargetConfig/Manage')),
    isFullPage: true,
  },
  {
    // 动态配置
    path: '/op/contentHeating/targetConfig/edit/:key',
    Component: lazy(() => import('opPages/ContentHeating/DeliveryTargetConfig/TargetEdit')),
    isFullPage: true,
  },
  {
    // 充值单看板
    path: '/op/contentHeating/invoiceManage',
    Component: lazy(() => import('opPages/ContentHeating/InvoiceManage')),
    isFullPage: true,
  },
  {
    // 券配置
    path: '/op/contentHeating/couponConfig',
    Component: lazy(() => import('opPages/ContentHeating/CouponsManage')),
    isFullPage: true,
  },
];

export default contentHeating;
