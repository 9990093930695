/**
 * @file lru memory cache
 */
import hash from 'object-hash';
import { LRUCache } from 'lru-cache';

export default class LruMemoryCache {
  lruCache: any;
  constructor() {
    this.lruCache = new LRUCache({ max: 20 });
  }

  /** set cache */
  set(url: string, requestBody: any, ttl: number) {
    const cacheIndex = hash.MD5([url, requestBody]);
    this.lruCache.set(cacheIndex, requestBody, { ttl });
  }

  /** get cache */
  get(url: string, requestBody: any) {
    const cacheIndex = hash.MD5([url, requestBody]);
    return this.lruCache.get(cacheIndex);
  }
}
