import { lazy } from 'react';
import { ROLE_ID } from 'services/account';
import { CallIcon } from 'tdesign-icons-react';
import { hasSetCustomServiceManagerRights } from 'utils/rights';
import { RouterType } from '../index';

const custom: RouterType[] = [
  {
    path: '/custom',
    meta: {
      title: '客服管理',
      Icon: CallIcon,
    },
    children: [
      {
        path: 'permission',
        checkRole: hasSetCustomServiceManagerRights,
        Component: lazy(() => import('pages/CustomService/Permission')),
        meta: {
          title: '设置客服管理权限',
        },
      },
      {
        path: 'manage',
        Component: lazy(() => import('pages/CustomService/Manage')),
        checkRole: (role) => [ROLE_ID.manager, ROLE_ID.customer].includes(role?.roleId as ROLE_ID),
        meta: {
          title: 'QQ小店客服',
        },
      },
      {
        path: 'work',
        Component: lazy(() => import('pages/CustomService/Work')),
        checkRole: (role) => [ROLE_ID.manager, ROLE_ID.customer].includes(role?.roleId as ROLE_ID),
        meta: {
          title: '客服工作台',
        },
      },
    ],
  },
];

export default custom;
