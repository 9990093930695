import { lazy } from 'react';
import type { RouterType } from 'types';

const violations: RouterType[] = [
  {
    // 违规分管理
    path: '/op/violation/manage',
    Component: lazy(() => import('opPages/Violation/Manage')),
    isFullPage: true,
  },
  {
    // 店铺违规分管理
    path: '/op/violation/shop/:id',
    Component: lazy(() => import('opPages/Violation/Manage/Shop')),
    isFullPage: true,
  },
  {
    // 新建违规分
    path: '/op/violation/create',
    Component: lazy(() => import('opPages/Violation/Create')),
    isFullPage: true,
  },
  {
    // 编辑违规分
    path: '/op/violation/edit/:id',
    Component: lazy(() => import('opPages/Violation/Create/Edit')),
    isFullPage: true,
  },
  {
    // 违规单详情
    path: '/op/violation/detail/:id',
    Component: lazy(() => import('opPages/Violation/Detail')),
    isFullPage: true,
  },
];

export default violations;
