import { lazy } from 'react';
import { RouterType } from '../index';

const afterSale: RouterType[] = [
  {
    path: '/faceVerify',
    isTopLayout: true,
    Component: lazy(() => import('pages/ShopV2/FaceVerify')),
  },
];

export default afterSale;
