import React, { memo, useEffect, useMemo } from 'react';
import { Row, Col } from 'tdesign-react';
import { ServiceIcon } from 'tdesign-icons-react';
import Style from './HeaderIcon.module.less';
import { logoutAction } from 'utils/login';
import ShopInfo from './ShopInfo';
import { initSDK, CUSTOM_SERVICE_CLASSNAME, ZHICHI_SCRIPT_ID } from './utils';
import { useAppSelector } from 'modules/store';
import { SHOP_REVIEW_STATUS } from 'modules/account';
import aegis from 'utils/aegis';

/** 清理智齿组件遗留残渣 */
const clearCustomService = () => {
  const removeById = (id: string) => {
    const el = document.getElementById(id);
    el?.parentNode?.removeChild(el);
  };
  removeById('zc__sdk__container');
  removeById(ZHICHI_SCRIPT_ID);
  removeById('zc__wapper__shadow');
  removeById('zc__bubble_container');
  (window as any).zc = undefined;
  (window as any).cbk = undefined;
};

export default memo(() => {
  const handleLogout = async () => {
    aegis.infoAll('===用户主动退出登录===');
    logoutAction(true);
  };

  const { shopInfo, isLogin } = useAppSelector((state) => ({
    shopInfo: state.account.shopInfo,
    isLogin: state.account.isLogin,
  }));
  const showCustomService = useMemo(
    () => [SHOP_REVIEW_STATUS.infoPass].includes(shopInfo?.shopReviewStatus as any),
    [shopInfo],
  );

  useEffect(() => {
    if (!showCustomService) {
      return clearCustomService;
    }

    const clear = initSDK();
    return () => {
      clearCustomService();
      clear();
    };
  }, [showCustomService]);

  return (
    <Row align="middle" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: 300 }}>
      <Col
        style={{
          display: showCustomService ? 'flex' : 'none',
          alignItems: 'center',
          marginRight: 40,
          cursor: 'pointer',
        }}
      >
        <div className={CUSTOM_SERVICE_CLASSNAME}>
          <ServiceIcon size="22" />
        </div>
      </Col>

      <Col style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
        <ShopInfo />
      </Col>

      {isLogin && (
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <div onClick={handleLogout} className={Style.logout}>
            退出
          </div>
        </Col>
      )}
    </Row>
  );
});
