import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';

import global from './global';
import settle from './settle';
import settleV2 from './settleV2';
import product from './product';
import contract from './shop/contract';
import loginMaterial from './login';
// import user from './user';
// import listBase from './list/base';
// import listSelect from './list/select';
import logisticsModule from './delivery/logisticsModule';
import account from './account';
import reportCenter from './reportCenter';

const reducer = combineReducers({
  global,
  settle,
  logisticsModule,
  product,
  contract,
  account,
  settleV2,
  loginMaterial,
  reportCenter,
  // user,
  // listBase,
  // listSelect,
});

export const store = configureStore({
  reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
