import { MessagePlugin } from 'tdesign-react';
import { humpPost } from 'utils/request';

export const PERMISSION_DENIED_MSG = '暂无权限';
export const PERMISSION_CLOSE_CODE = 31009;
export const CUSTOMSERVICE_URL = `https://${
  import.meta.env.VITE_API_HOST
}/trpc.ecom.qshop_customer_reader_http_svr.QshopCustomerReaderHttpSvr`;

const post = async <T>(path: string, data: any, noToast = false) => {
  try {
    const res = await humpPost<
      {
        retCode: number;
        retMsg: string;
      } & T
    >(`${CUSTOMSERVICE_URL}${path}`, data);
    if (res.retCode !== 0) {
      // 无权限
      if (res.retCode === 31008) {
        throw new Error(PERMISSION_DENIED_MSG);
      }
      // 关闭客服权限
      if (res.retCode === PERMISSION_CLOSE_CODE) {
        return res;
      }
      const errMsg = res.retMsg || '网络异常';
      throw new Error(errMsg);
    }
    return res;
  } catch (e: any) {
    if (!noToast) {
      MessagePlugin.error(e.message || '网络异常', 2000);
    }
    throw e;
  }
};

// 运营/商家端客服场景
export enum WEB_CUSTOMER_SERVICE_SCENE {
  /** 未知 */
  UNKNOWN = 0,
  /** 商家端申诉场景聊天链接 */
  appeal = 1,
  /** 智齿客服工作台 */
  zcCustommerWorkPlatform = 2,
  /** 智齿客服管理平台 */
  zcCustommerManagePlatform = 3,
}

export interface GetLinkURLReq {
  // 客服服务场景
  scene: WEB_CUSTOMER_SERVICE_SCENE;
}

export interface LoginSSoRsp {
  retCode: number;
  retMsg: string;
  // 参考文档：https://www.sobot.com/developerdocs/service/sign_on.html
  url: string;
}

export interface ParamsItem {
  key: string;
  value: string;
}

/** 登录客服工作台 */
export const loginWorkPlatform = () =>
  post<{ url: string }>('/LoginSSo', { scene: WEB_CUSTOMER_SERVICE_SCENE.zcCustommerWorkPlatform }, true);
/** 登录智齿客服管理平台 */
export const loginManagePlatform = () =>
  post<{ url: string }>('/LoginSSo', { scene: WEB_CUSTOMER_SERVICE_SCENE.zcCustommerManagePlatform }, true);

export const getLinkURLShopManage = () =>
  post<{ chatUrl: string }>('/GetLinkURLShopManage', { scene: WEB_CUSTOMER_SERVICE_SCENE.appeal });

export const getLinkURLParams = () =>
  post<{ uriParams: ParamsItem[] }>('/GetLinkURLParams', { scene: WEB_CUSTOMER_SERVICE_SCENE.appeal });

interface GetZcManageHistoryFlowReq {
  /** 页码：从0开始 */
  from: number;
  /** 默认size = 10 */
  size?: number;
}

export interface ZcManageHistoryFlowItem {
  /** 设置者账户名 */
  operatorName: string;
  /** 被设置用户账户名 */
  userName: string;
  /** 是否有权限 */
  hasRight: boolean;
  /** 设置时间，毫秒 */
  ts: number;
}

interface GetZcManageHistoryFlowRsp {
  total: number;
  zcFlow: ZcManageHistoryFlowItem[];
}

/** 查询智齿超管修改流水 */
export const getZcManageHistoryFlow = (data: GetZcManageHistoryFlowReq) =>
  post<GetZcManageHistoryFlowRsp>('/GetZcManageHistoryFlow', data);

/** 开通客服 */
export const openCustomerPermission = async () => {
  const res = await humpPost(`${CUSTOMSERVICE_URL}/RegisterCustomer`, {});
  if (res.retCode === 0) {
    MessagePlugin.success('开通成功');
    return true;
  }
  const err = res.retMsg || '服务异常';
  MessagePlugin.error(err, 2000);
  throw new Error(err);
};
