import request from './request';
import { Cgi } from '../configs/login';
import { MessagePlugin } from 'tdesign-react';
// import { replace } from './navigate';
import store from 'modules/store';
import { clearAccountInfo, SHOP_PUNISH_STATUS, SHOP_REVIEW_STATUS, setLogoutFromHeaderMenu } from 'modules/account';
import { LOGIN_PATH } from 'router';
import { SMSLogout } from 'services/luAccount';
import throttle from 'lodash/throttle';
import aegis from 'utils/aegis';
import Cookie from 'utils/cookie';
import { Cfg as LoginCfg } from 'configs/login';
// ==============================================> Types

export enum LOGINTYPE {
  LOGIN_UNKNOWN = 0,
  LOGIN_MASTER = 1,
  LOGIN_SLAVE = 2,
}
export interface ILoginShop {
  shopId?: string;
  phoneNum: string;
  type?: LOGINTYPE;
}

// ==============================================> Funcs
const loginShop = async (params: ILoginShop) => {
  const res = { code: -1, canLogin: 0, msg: '', rsp: {} };
  try {
    const { shopId = '', phoneNum = '', type = LOGINTYPE.LOGIN_UNKNOWN } = params;
    const data = {
      login_type: type,
      shop_id: shopId,
      phone_num: phoneNum,
    };
    const loginRes = await request.post(Cgi.loginShop, data);
    console.info('[loginShop][res]:', loginRes);
    const { retcode = -1, can_login: canLogin = 0 } = loginRes as any;
    res.code = retcode;
    res.canLogin = canLogin;
  } catch (err) {
    console.error('[loginShop][err]', err);
  }
  return res;
};

export const logoutAction = throttle(async (noNotice = false) => {
  aegis.infoAll('===执行退出登录===');
  await SMSLogout();
  const { hash } = window.location;
  if (hash !== `#${LOGIN_PATH}` && !noNotice) {
    await new Promise((res) => {
      const delay = 2000;
      MessagePlugin.info('登录已过期, 请重新登录', delay);
      setTimeout(res, delay);
    });
  }
  store.dispatch(clearAccountInfo);
  setLogoutFromHeaderMenu();
}, 1000);

// interface CheckShopStatusRes {
//   code: number
//   status?:
// }

const checkShopStatus = async (manageShopId?: string) => {
  const res = {
    code: -1,
    status: SHOP_REVIEW_STATUS.empty,
    shopId: '',
    name: '',
    shopLogoUrl: '',
    shopPunishStatus: SHOP_PUNISH_STATUS.no,
  };

  const result: any = await request.post(Cgi.checkShop, { shop_id: manageShopId });
  res.code = 0;
  const { retcode = -1, rsp = {}, retmsg } = result;
  const { base_info: baseInfo = {} } = (rsp || {}) as any;
  const { shop_id: shopId = '', shop_review_status: shopStatus = -1, shop_logo_url: shopLogoUrl } = baseInfo;
  if (retcode === 33000) {
    res.status = SHOP_REVIEW_STATUS.empty;
  } else if (retcode === 0) {
    res.status = shopStatus;
  } else {
    MessagePlugin.error(retmsg || '网络异常');
    throw new Error(`网络错误：${retmsg}`);
  }
  res.shopLogoUrl = shopLogoUrl;
  res.shopId = shopId;
  res.name = baseInfo.shop_name;
  res.shopPunishStatus = baseInfo.shop_punish_status;
  return res;
};

// ==============================================> Export
export { checkShopStatus, loginShop };
