import { LargeNumberLike } from 'crypto';
import { MessagePlugin } from 'tdesign-react';

import { humpPost } from 'utils/request';

const authUrlPrifix = `https://${import.meta.env.VITE_API_HOST}/trpc.ecom.api_auth_manage_svr.AuthManageSvrHttp`;

export interface IPageGrantInfoReq {
  userId: string;
  granted: boolean;
}
export enum GRANT_STATUS {
  /** 初始化 */
  INT,
  /** 已授权 */
  GRANTED,
  /** 已撤销 */
  REVOKED,
  /** 已过期 */
  EXPIRED,
}

export interface IAppInfo {
  appid: string; // 供货商APPID
  appSecret: string; // 前端不展示
  redirectUri: string; // 重定向url
  scope: string; // 权限组 ","分隔
  appName: string; // 供货商名称
}
export interface ICompanyInfo {
  companyName: string;
}
export interface ITagInfo {
  tagId: string;
  tagName: string;
}
export interface IAppDetail {
  desc: string; // 供货商APPID
  logo: string; // 前端不展示
  appInfo: IAppInfo; // 供货商信息
  tags: Array<ITagInfo>;
  company: ICompanyInfo;
  homePageUrl: string;
}

export interface IGrantInfo {
  appDetail: IAppDetail;
  startTime: number; // 授权始末时间
  endTime: LargeNumberLike; // 授权始末时间
  status: GRANT_STATUS; // 授权状态枚举
  state: string; // 供货商的店铺识别码
}

export interface ItUserAuthInfo {
  userId: string;
  userSession: string;
  shopId: string;
}
export interface IGrantAppReq {
  userId: string;
  appid: string;
  extData: any;
}

export interface IGetAppInfoReq {
  appid: string;
}

export interface IGetCodeReq {
  appid: string;
  shopName: string;
}

export interface IGetCodeRsp {
  code: string;
  redirectUri: string;
}
export const pageGrantInfo = async (params: IPageGrantInfoReq): Promise<Array<IGrantInfo>> => {
  const rsp = await humpPost(`${authUrlPrifix}/PageGrantInfo`, params);
  console.log('pageGrantInfo rsp', rsp);

  if (rsp.code !== 0) {
    MessagePlugin.error('铺货工具接口调用失败,', rsp.msg);
    throw new Error('pageGrantInfo error');
  }

  return rsp.data.grantInfos || [];
};

export const getListDistributeService = async () => {
  const rsp = await humpPost(`${authUrlPrifix}/ListDistributeService`, {});

  if (rsp.code !== 0) {
    MessagePlugin.error('铺货工具接口调用失败,', rsp.msg);
    throw new Error('ListDistributeService error');
  }

  return rsp.data.grantInfos || [];
};

export const grantApp = async (params: IGrantAppReq): Promise<any> => {
  const rsp = await humpPost(`${authUrlPrifix}/GrantApp`, params);
  console.log('grantApp rsp', rsp);
  return rsp;
};

export const unGrantApp = async (params: IGrantAppReq): Promise<void> => {
  const rsp = await humpPost(`${authUrlPrifix}/UnGrantApp`, params);
  console.log('unGrantApp rsp', rsp);
};

export const getAppInfo = async (params: IGetAppInfoReq): Promise<IGrantInfo> => {
  const rsp = await humpPost(`${authUrlPrifix}/GetAppInfo`, params);
  console.log('getAppInfo rsp', rsp);

  return {} as any;
};
export const getCode = async (params: IGetCodeReq): Promise<any> => {
  const rsp = await humpPost(`${authUrlPrifix}/GetCode`, params);
  console.log('getCode rsp', rsp);

  return rsp;
};
