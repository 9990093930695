import { lazy } from 'react';
import type { RouterType } from 'types';

const shopDataCenter: RouterType[] = [
  {
    // 商城数据中心
    path: '/op/shopDataCenter/view',
    Component: lazy(() => import('opPages/ShopDataCenter/DataView')),
    isFullPage: true,
  },
];

export default shopDataCenter;
