import { lazy } from 'react';
import type { RouterType } from 'types';

const brandAdvertisement: RouterType[] = [
  {
    // 投放-品牌广告
    path: '/op/brandAdvertisement/manage',
    Component: lazy(() => import('opPages/DeliveryPlan/BrandAdvertisement')),
    isFullPage: true,
  },
];

export default brandAdvertisement;
