import { lazy } from 'react';
import type { RouterType } from 'types';

const daziDeliver: RouterType[] = [
  {
    // 新建配置
    path: '/op/daziDeliver/create',
    Component: lazy(() => import('opPages/DaziDeliver/Create')),
    isFullPage: true,
  },

  {
    // 投放任务查询
    path: '/op/daziDeliver/manage',
    Component: lazy(() => import('opPages/DaziDeliver/Manage')),
    isFullPage: true,
  },
];

export default daziDeliver;
