import React, { Suspense, useEffect, CSSProperties } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Layout, Loading } from 'tdesign-react';
import routers, { RouterType } from 'router';
import { resolve } from 'utils/path';
import { RootState, useAppDispatch, useAppSelector } from '../../modules/store';
import { ELayout, switchFullPage, switchLayout } from '../../modules/global';
import Style from './Content.module.less';

const { Content } = Layout;

const PageBox = ({
  children,
  isFullPage,
  isTopLayout,
}: React.PropsWithChildren<{
  isFullPage?: boolean;
  isTopLayout?: boolean;
}>) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isTopLayout) {
      dispatch(switchFullPage(false));
      dispatch(switchLayout(ELayout.top));
      return;
    }
    dispatch(switchFullPage(isFullPage));
    if (!isFullPage) {
      dispatch(switchLayout(ELayout.side));
    }
  }, [isFullPage, isTopLayout]);

  return <>{children}</>;
};

const PageLoading = React.memo(
  Object.assign(
    () => (
      <div className={Style.loading}>
        <Loading />
      </div>
    ),
    { displayName: 'PageLoading' },
  ),
);

const renderRoutes = (routes: RouterType[], account: RootState['account'], parentPath = ''): React.ReactNode[] =>
  routes.map((route, index: number) => {
    const { Component, children, redirect } = route;
    const currentPath = resolve(parentPath, route.path);

    let checkRolePassed = true;
    if (route.checkRole) {
      if (!account.roleInfo) {
        checkRolePassed = false;
      } else {
        route.checkRole(account.roleInfo);
      }
    }

    if (redirect) {
      // 重定向
      return <Route key={index} path={currentPath} element={<Navigate to={redirect} replace />} />;
    }

    if (!checkRolePassed) {
      return <Route key={index} path={currentPath} element={<Navigate to={'/403'} replace />} />;
    }

    if (Component) {
      return (
        <Route
          key={index}
          path={currentPath}
          element={
            <PageBox isFullPage={route.isFullPage} isTopLayout={route.isTopLayout}>
              <Component />
            </PageBox>
          }
        >
          {children && renderRoutes(children, account, currentPath)}
        </Route>
      );
    }
    if (children) {
      return renderRoutes(children, account, currentPath);
    }
    return null;
  });

interface ContentProps {
  style?: CSSProperties;
}

const ContentContainer = (props: ContentProps) => {
  const account = useAppSelector((state) => state.account);
  const { style } = props;

  return (
    <Content style={{ background: 'var(--td-bg-color-page)', ...style }}>
      <Suspense fallback={<PageLoading />}>
        <Routes>{renderRoutes(routers, account)}</Routes>
      </Suspense>
    </Content>
  );
};
ContentContainer.displayName = 'ContentContainer';
export default ContentContainer;
