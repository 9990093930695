import { lazy } from 'react';
import { ShopIcon } from 'tdesign-icons-react';
import { RouterType } from '../index';

const afterSale: RouterType[] = [
  {
    path: '/afterSale',
    meta: { title: '售后管理', Icon: ShopIcon },
    children: [
      {
        path: 'list',
        Component: lazy(() => import('pages/AfterSale')),
        meta: { title: '售后单' },
        matchPaths: ['/afterSale/detail'],
      },
      {
        path: 'detail',
        Component: lazy(() => import('pages/AfterSale/AfterSaleDetail')),
      },
    ],
  },
];

export default afterSale;
