import { getLinkURLParams } from 'services/customService';

export const CUSTOM_SERVICE_CLASSNAME = 'zhiCustomBtn';
export const ZHICHI_SCRIPT_ID = 'zhichiScript';

export const loadSDK = (sysnum: string) => {
  // eslint-disable-next-line func-names
  (function (w: any, d: any, e: any) {
    // eslint-disable-next-line func-names
    w[e] = function () {
      w.cbk = w.cbk || [];
      // eslint-disable-next-line prefer-rest-params
      w.cbk.push(arguments);
    };
    const x: any = d.createElement('script');
    x.async = true;
    x.id = ZHICHI_SCRIPT_ID;
    x.src = `https://${import.meta.env.VITE_ZC_SDK}?sysnum=${sysnum}`;
    x.className = CUSTOM_SERVICE_CLASSNAME; // 该class绑定到自定义按钮上 第一步
    d.body.appendChild(x);
  })(window, document, 'zc');
};

export const initSDK = () => {
  const timer = setTimeout(async () => {
    const res = await getLinkURLParams();

    const config: Record<string, any> = {
      color: 'ff3370',
      custom: true,
      feedback_flag: 0,
      container_style: 'bottom: 40px; right: 24px',
    };
    res.uriParams.forEach((item) => {
      config[item.key] = item.value;
    });

    loadSDK(config.sysnum);
    window.zc('config', config);
  }, 300);

  return () => {
    clearTimeout(timer);
  };
};
