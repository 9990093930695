import { humpPost } from 'utils/request';

import {
  CanCelUploadRsp,
  DownloadRecordReq,
  DownloadRecordRsp,
  IsCardShopRsp,
  QueryUploadTaskReq,
  QueryUploadTaskRsp,
  UploadReq,
  UploadRsp
} from './types';

const bulkProductUrlPrefix = `https://${import.meta.env.VITE_API_HOST}/qqcards`;
const downloadProductsUrlPrefix = `https://${import.meta.env.VITE_API_HOST}/qqcards_noprotocol`;
export const getBulkProductRight = async () => {
  const result = (await humpPost(`${bulkProductUrlPrefix}/iscardshop`, {})) as IsCardShopRsp;
  const { isCardShop } = result.rsp;
  return isCardShop;
};

export const uploadTask = async (req: UploadReq) => {
  const result = (await humpPost(`${bulkProductUrlPrefix}/upload`, req)) as UploadRsp;
  const { rsp, retcode, retmsg } = result;
  // 存在异常
  if (retcode) {
    throw retmsg;
  }
  return rsp;
};

export const cancelUploadTask = async (taskId: string) => {
  const result = (await humpPost(`${bulkProductUrlPrefix}/cancelupload`, { taskId })) as CanCelUploadRsp;
  return result;
};

export const queryUploadTask = async (req: QueryUploadTaskReq) => {
  const result = (await humpPost(`${bulkProductUrlPrefix}/queryuploadtask`, req)) as QueryUploadTaskRsp;
  return result.rsp;
};

export const downloadTaskProductRecord = async (req: DownloadRecordReq) => {
  const result = (await humpPost(`${downloadProductsUrlPrefix}/downloadproducts`, req)) as DownloadRecordRsp;
  return result;
};
