import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getContractList, IShopAgreementDetail } from 'services/shopcontract';

const namespace = 'shop/contract';

interface IInitialState {
  loading: boolean;
  current: number;
  pageSize: number;
  total: number;
  contractList: IShopAgreementDetail[];
  curContract: IShopAgreementDetail;
}

const initialState: IInitialState = {
  loading: true,
  current: 1,
  pageSize: 10,
  total: 0,
  contractList: [],
  curContract: {
    agreement_id: '', // 合同编号，上传的时候不需要填，后端生成
    agreement_name: '', // 合同名称
    agreement_url: '',
    agreement_sign_status: 0, // 合同签约状态 0: 待签约 1: 已签约
    current_agreement_index: '', // 合同索引编号
    is_delete: false,
    shop_id: '',
  },
};

export const getList = createAsyncThunk(
  `${namespace}/getList`,
  async (params: { pageSize: number; current: number }) => {
    const result = await getContractList(params);

    console.log('result', result);
    return {
      list: result?.list,
      total: result?.total,
      pageSize: params.pageSize,
      current: params.current,
    };
  },
);

const shopContractSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearPageState: () => initialState,
    setCurContract: (state, action) => {
      console.log('action', action);
      state.curContract = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getList.fulfilled, (state, action) => {
        state.loading = false;
        state.contractList = action.payload?.list;
        state.total = action.payload?.total;
        state.pageSize = action.payload?.pageSize;
        state.current = action.payload?.current;
      })
      .addCase(getList.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { clearPageState, setCurContract } = shopContractSlice.actions;

export const shopContract = (state: RootState) => state.contract;

export default shopContractSlice.reducer;
