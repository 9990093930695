import React, { memo } from 'react';
import Style from '../Menu.module.less';
import { useNavigate } from 'react-router-dom';
import s from './index.module.less';
import Logo from 'components/Logo';
import { HOME_PATH } from 'router';

interface IProps {
  collapsed?: boolean;
}

export default memo((props: IProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(HOME_PATH);
  };

  return (
    <div
      className={Style.menuLogo}
      onClick={handleClick}
      style={{ paddingLeft: props.collapsed ? 0 : 22, justifyContent: props.collapsed ? 'center' : 'flex-start' }}
    >
      {props.collapsed ? (
        <Logo mini />
      ) : (
        <div className={s.full}>
          <Logo />
        </div>
      )}
    </div>
  );
});
