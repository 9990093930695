import { MessagePlugin } from 'tdesign-react';
import { tcaptchaErrCode } from './err';

const APP_ID = '2064074341';

// 加载验证码sdk
let hasLoadSdk = false;
const loadTcaptchaSDK = () =>
  new Promise<void>((res, rej) => {
    if (hasLoadSdk) {
      res();
      return;
    }
    const src = 'https://t.captcha.qq.com/TCaptcha.js';
    const script = document.createElement('script');
    script.onload = () => {
      hasLoadSdk = true;
      res();
    };
    script.onerror = rej;
    script.onabort = rej;
    script.setAttribute('src', src);
    document.body.appendChild(script);
  });

interface TcaptchaRes {
  ret: 0;
  ticket: string;
  randstr: string;
  errorMessage: string;
  errorCode: number;
}
// 加载并调用验证码sdk
export const tcaptchaWall = async () => {
  await loadTcaptchaSDK();
  return new Promise<TcaptchaRes>((resolve, reject) => {
    function callback(res: TcaptchaRes) {
      /* res（验证成功） = {ret: 0, ticket: "string", randstr: "String"}
         res（客户端出现异常错误 仍返回可用票据） = {ret: 0, ticket: "String", randstr: "String",
                                               errorCode: Number, errorMessage: "String"}
         res（用户主动关闭验证码）= {ret: 2}
      */
      if (res.ret === 0) {
        resolve(res);
        return;
      }
      const msg = tcaptchaErrCode[res.errorCode] || '验证码加载失败';
      MessagePlugin.error(msg);
      reject(new Error(msg));
    }
    // 验证码js加载错误处理
    function loadErrorCallback() {
      const appid = APP_ID;
      // 生成票据或自行做其它处理
      const ticket = `terror_1001_${appid}${Math.floor(new Date().getTime() / 1000)}`;
      callback({
        ret: 0,
        // eslint-disable-next-line newline-per-chained-call
        randstr: `@${Math.random().toString(36).substr(2)}`,
        ticket,
        errorCode: 1001,
        errorMessage: 'jsload_error',
      });
    }

    try {
      // 生成一个验证码对象
      const captcha = new window.TencentCaptcha(
        '2064074341', // 验证码appid
        callback, // 验证码回调函数
        {
          // 验证码options配置参数
          ready() {},
          needFeedBack: true,
        },
      );
      // 显示验证码
      captcha.show();
    } catch (error) {
      loadErrorCallback();
    }
  });
};
