// 商品售卖状态
export enum ProductSaleStatus {
  PRODUCT_SALE_STATUS_UNKNOWN = 0,
  PRODUCT_SALE_STATUS_ON_SALE = 1, // 上架
  PRODUCT_SALE_STATUS_OFF_SALE = 2, // 下架
  PRODUCT_SALE_STATUS_ARCHIVED = 3, // 回收站
}

// 商品审核状态
export enum ProductAuditStatus {
  PRODUCT_AUDIT_STATUS_UNKNOWN = 0,
  PRODUCT_AUDIT_STATUS_PENDING = 1, // 审核中
  PRODUCT_AUDIT_STATUS_REJECTED = 2, // 审核拒绝
  PRODUCT_AUDIT_STATUS_PASSED = 3, // 审核通过
}

export enum DeliveryMode {
  /** 现货模式 */
  DELIVERY_MODE_OFF_THE_SHELF = 0,
  /** 预售模式 */
  DELIVERY_MODE_PRE_SALE = 1,
}

/** 发货模式 */
export const DeliveryModeTypes = [
  { value: DeliveryMode.DELIVERY_MODE_OFF_THE_SHELF, label: '现货发货' },
  { value: DeliveryMode.DELIVERY_MODE_PRE_SALE, label: '预售发货' },
];

/** 发货库存 */
export const DeliveryModeStock = [
  { value: DeliveryMode.DELIVERY_MODE_OFF_THE_SHELF, label: '现货库存（件）' },
  { value: DeliveryMode.DELIVERY_MODE_PRE_SALE, label: '预售库存（件）' },
];

export enum DeliveryTime {
  /** 48小时内发货 */
  DELIVERY_OPPORTUNITY_48_HOURS = 0,
  /** 当日发货 */
  DELIVERY_OPPORTUNITY_SAME_DAY = 1,
  /** 次日发货 */
  DELIVERY_OPPORTUNITY_NEXT_DAY = 2,
  /** 2-15天内发货 */
  DELIVERY_OPPORTUNITY_2_15_DAY = 3,
}

/** 发货时间 */
export const DeliveryTimeType = [
  { value: DeliveryTime.DELIVERY_OPPORTUNITY_48_HOURS, label: '48小时内发货' },
  { value: DeliveryTime.DELIVERY_OPPORTUNITY_SAME_DAY, label: '当日发货' },
  { value: DeliveryTime.DELIVERY_OPPORTUNITY_NEXT_DAY, label: '次日发货' },
];

/** 发货时机 */
export enum DeliveryOpportunity {
  /** 支付成功后 */
  DELIVERY_AFTER_PAYMENT = 0,
  /** 预售结束后 */
  DELIVERY_AFTER_PRE_SALE_END = 1,
}

/** 发货时间 */
export const DeliveryOpportunityOptions = [
  { value: DeliveryOpportunity.DELIVERY_AFTER_PAYMENT, label: '支付成功后' },
  { value: DeliveryOpportunity.DELIVERY_AFTER_PRE_SALE_END, label: '预售结束后' },
];

export interface Specification {
  id: string;
  name: string;
  value: string;
}

export interface Pic {
  width: number;
  height: number;
  url: string;
  isGif: boolean;
}

export interface Sku {
  skuId: string;
  skuCode: string;
  spec: {
    items: Specification[];
  };
  price: string;
  previewPics: Pic[];
  sales?: {
    order: string;
    sales: string;
  };
  status?: number;
  stock: string;
  oriStock?: string;
}

export interface ICategoryGroup {
  firstCid: string;
  firstName: string;
  fourthCid: string;
  fourthName: string;
  groupId: string;
  groupLevel: number;
  isLeaf: boolean;
  secondCid: string;
  secondName: string;
  thirdCid: string;
  thirdName: string;
  firstCatId?: string;
  firstCatName?: string;
  fourthCatId?: string;
  fourthCatName?: string;
  secondCatId?: string;
  secondCatName?: string;
  thirdCatId?: string;
  thirdCatName?: string;
}

export interface Property {
  propertyId?: string;
  id: string;
  name: string;
  value: string | number;
}

// 商品交易类型，包括实物商品(需要发货)、充值类虚拟商品
export enum ProductTradeType {
  PRODUCT_TRADE_TYPE_THINGS = 0, // 默认为实物商品
  PRODUCT_TRADE_TYPE_PREPAID_VIRTUAL = 1, // 充值类虚拟商品
}

export enum VirtualAccountType {
  VIRTUAL_ACCOUNT_TYPE_UNKONWN = 0,
  VIRTUAL_ACCOUNT_TYPE_PHONE = 1, // 手机号
  VIRTUAL_ACCOUNT_TYPE_PLATFORM = 2, // 平台账号
}
export interface ProductTradeInfo {
  tradeType: ProductTradeType;
  accountType: VirtualAccountType;
  accountName: string;
  deliveryType: 1 | 2;
  deliveryMode: DeliveryMode;
  deliveryTimeType: DeliveryTime;
  deliveryDay: number;
  presaleEndTime: number;
  deliveryOpportunity: DeliveryOpportunity;
  hasEndTime: boolean;
}

export interface Spu {
  shopId: string;
  spuId: string;
  stock?: string;
  title: string;
  brandId: string;
  createTime?: string;
  updateTime?: string;
  category: ICategoryGroup;
  desc: string;
  detailPics: Pic[] | [];
  mainPics: Pic[] | [];
  price: {
    max: string;
    min: string;
    origin: string;
  };
  property: {
    items: Property[] | [];
  };
  sales?: {
    order: string;
    sales: string;
  };
  tradeInfo?: ProductTradeInfo;
}

export interface Express {
  serviceType: number;
  title: string;
  icon: string;
  desc: string;
}

// 商品
export interface Product {
  bizType: string;
  buyLimit?: {
    userDailyLimit: number;
  };
  brand: {
    id: string;
    name: string;
  };
  skus: Sku[];
  spu: Spu;
  mysteryBox?: {
    memberType: number;
    mysteryBoxType: MysteryBoxType;
  };
  service: {
    items: Express[];
    shipTemplateId: string;
  };
  status: {
    auditRejectReason?: string;
    saleStatusReason?: string;
    auditStatus: number;
    canSell: boolean;
    saleStatus: number;
  };
}
/** 盲盒成员类型 */
export enum MysteryBoxMemberType {
  /** 常规商品 */
  MYSTERY_BOX_MEMBER_TYPE_NORMAL,

  /** 盲盒主体 */
  MYSTERY_BOX_MEMBER_TYPE_MAIN,

  /** 盲盒成员 */
  MYSTERY_BOX_MEMBER_TYPE_MEMBER,
}

/** 盲盒类型 */
export enum MysteryBoxType {
  /** 极光赏 */
  MYSTERY_BOX_TYPE_JIGUANG,
  /** 一番赏 */
  MYSTERY_BOX_TYPE_YIFAN,
}

export interface PropertyRelate {
  id: string;
  propertyId: string;
  isFill: boolean;
}

export interface Category {
  name: string;
  id: string;
  level: number;
  status: number;
  parentId: string;
  categoryGroup: ICategoryGroup;
  relatePropertys: PropertyRelate[];
  children?: Category[];
}

export interface CategoryOption {
  label: string;
  value: string;
  labels: string[];
  categoryGroup?: ICategoryGroup;
  property: PropertyRelate[];
  children?: CategoryOption[];
}
export interface LeafCategory {
  label: string;
  value: string;
  disabled: boolean;
}

export interface CategoryInfo {
  categoryInfo: Category;
  isManage: boolean;
  subCategory: CategoryInfo[];
}

export interface CategoryHistory {
  categoryGroup: ICategoryGroup;
  hasPermission: boolean;
}
