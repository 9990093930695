import React, { memo } from 'react';
import { Layout, Row } from 'tdesign-react';
import { useAppSelector } from 'modules/store';
import { selectGlobal } from 'modules/global';
import HeaderIcon from './HeaderIcon';
import Style from './Header.module.less';
import MenuLogo from './MenuLogo';
import HeaderMenu from './HeaderMenu';

const { Header } = Layout;

interface IHeaderProps {
  showMenu?: boolean;
  isTopLayout?: boolean;
}

export default memo((props: IHeaderProps) => {
  const globalState = useAppSelector(selectGlobal);
  const { isTopLayout } = props;

  if (!globalState.showHeader) {
    return null;
  }

  const HeaderLeft = (
    <Row align='middle' style={{ position: 'relative', width: '100%' }}>
      {isTopLayout && <MenuLogo />}
      {isTopLayout && <HeaderMenu styleObj={{ position: 'absolute', left: '138px', marginTop: '4px' }} />}
    </Row>
  );

  return (
    <>
      <Header className={Style.headerPanel}>
        {HeaderLeft}
        <HeaderIcon />
      </Header>
    </>
  );
});
