import { lazy } from 'react';
import { ROLE_ID } from 'services/account';
import { HomeIcon } from 'tdesign-icons-react';
import { RouterType } from '../index';
import { hasShopManageRights } from 'utils/rights';

const checkRole: RouterType['checkRole'] = (roleInfo) => roleInfo?.roleId === ROLE_ID.manager;

const settle: RouterType[] = [
  {
    path: '/shop',
    meta: { title: '店铺管理', Icon: HomeIcon },
    children: [
      {
        path: 'operatorManage',
        Component: lazy(() => import('pages/Shop/OperatorManage')),
        meta: { title: 'QQ运营' },
        checkRole,
        matchPaths: ['/shop/operatorPromoterManage'],
      },
      {
        path: 'operatorPromoterManage',
        Component: lazy(() => import('pages/Shop/OperatorManage/components/Operator')),
        checkRole,
      },
      {
        path: 'subUserManage',
        Component: lazy(() => import('pages/Shop/SubUserManage')),
        meta: { title: '子账户管理' },
        checkRole,
        matchPaths: ['/shop/addUser', '/shop/roleDetails/:roleId'],
      },
      {
        path: 'addUser',
        Component: lazy(() => import('pages/Shop/SubUserManage/components/AddSubAccount')),
      },
      {
        path: 'roleDetails/:roleId',
        Component: lazy(() => import('pages/Shop/SubUserManage/Role/Details')),
      },
      // {
      //   path: 'quaManage',
      //   Component: lazy(() => import('pages/Shop/QuaManage')),
      //   meta: { title: '资质变更' },
      //   checkRole,
      // },
      // 真实的资质变更
      // 资质变更模块查看列表
      {
        path: 'quaList',
        Component: lazy(() => import('pages/ShopV2/QuaList')),
        meta: { title: '资质变更' },
        checkRole,
        matchPaths: [
          '/shop/modifyQua/:type/:reviewStatus',
          '/shop/modifyQua/:type',
          '/shop/modifyBrand/:id/:reviewStatus',
          '/shop/addBrand',
          '/shop/modifyCategory/:reviewStatus',
        ],
      },
      {
        path: 'authorizationList',
        Component: lazy(() => import('pages/Product/ProductExternalPublish/authorizationList')),
        meta: { title: '授权管理' },
        checkRole: (role) => {
          if (role?.roleId === ROLE_ID.customer) {
            return false;
          }
          return hasShopManageRights(role);
        },
      },
      {
        path: 'modifyQua/:type/:reviewStatus',
        Component: lazy(() => import('pages/ShopV2/Modify')),
        checkRole,
      },
      {
        path: 'modifyQua/:type',
        Component: lazy(() => import('pages/ShopV2/Modify')),
        checkRole,
      },
      {
        path: 'modifyCategory/:reviewStatus',
        Component: lazy(() => import('pages/ShopV2/Modify/pages/CategoryLicense')),
        checkRole,
      },
      // 品牌资质变更
      {
        path: 'modifyBrand/:id/:reviewStatus',
        Component: lazy(() => import('pages/ShopV2/Modify/pages/ModifyBrand')),
        checkRole,
      },
      // 新增品牌资质
      {
        path: 'addBrand',
        Component: lazy(() => import('pages/ShopV2/Modify/pages/AddBrand')),
        checkRole,
      },
      {
        path: 'bindShop',
        Component: lazy(() => import('pages/Shop/BindShop')),
        isFullPage: true,
      },
      {
        path: 'post',
        Component: lazy(() => import('pages/Shop/Announce')),
        // meta: { title: '公告管理' },
        checkRole,
      },
      {
        path: 'contractShop',
        Component: lazy(() => import('pages/Shop/Contract')),
        meta: { title: '合同管理' },
        checkRole,
      },
      {
        path: 'reportCenter',
        Component: lazy(() => import('pages/Shop/ReportCenter')),
        meta: { title: '报备中心' },
        checkRole,
        matchPaths: [
          '/reportCenter/shopReport',
          '/reportCenter/shopReport/:id',
          'reportCenter/orderReport',
          'reportCenter/orderReport/:id',
        ],
      },
      {
        path: 'reportCenter/shopReport',
        Component: lazy(() => import('pages/Shop/ReportCenter/components/ShopReport')),
        checkRole,
      },
      {
        path: 'reportCenter/shopReport/:id',
        Component: lazy(() => import('pages/Shop/ReportCenter/components/ShopReport')),
        checkRole,
      },
      {
        path: 'reportCenter/orderReport',
        Component: lazy(() => import('pages/Shop/ReportCenter/components/OrderReport')),
        checkRole,
      },
      {
        path: 'reportCenter/orderReport/:id',
        Component: lazy(() => import('pages/Shop/ReportCenter/components/OrderReport')),
        checkRole,
      },
      {
        path: 'reportCenter/reportDetail/:id',
        Component: lazy(() => import('pages/Shop/ReportCenter/components/ReportDetail')),
        checkRole,
      },
      {
        path: 'awardPunishCenter',
        Component: lazy(() => import('pages/Shop/AwardPunishCenter')),
        // meta: { title: '奖惩中心' },
        checkRole,
        matchPaths: ['/shop/violationDetail/:id', '/shop/penaltyDetail/:id'],
      },
      {
        path: 'violationDetail/:id',
        Component: lazy(() => import('pages/Shop/AwardPunishCenter/ViolationDetail')),
      },
      {
        path: 'penaltyDetail/:id',
        Component: lazy(() => import('pages/Shop/AwardPunishCenter/PenaltyDetail')),
      },
    ],
  },
];

export default settle;
