import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'modules/store';
import { getUserRightsInfo, IS_ALLOWED, logout, RoleInfo } from 'services/account';
import { isNotDef, storage } from 'utils';
import cookie from 'utils/cookie';
import { checkShopStatus, logoutAction } from 'utils/login';
import { getSettleAgreementInfo, signStatusEnum } from 'services/shopcontract';
import { getVerifyStatus, VERIFY_STATUS } from 'services/faceVerify';
import { HEADER_MENU_URL_LIST } from 'router';
import aegis from 'utils/aegis';
import { MessagePlugin } from 'tdesign-react';
import { getBulkProductRight } from 'services/bulk-product';

const namespace = 'account';
export const SETTLE_AGREEMENT_ID = `${import.meta.env.VITE_SETTLE_AGREEMENT_ID}`;

export const enum SHOP_REVIEW_STATUS {
  /** 未入驻， 前端自定义 */
  empty = -1,
  unkown = 0,
  /** 资质审核中 */
  infoPending = 1,
  /** 资质审核不通过 */
  infoReject = 2,
  /** 资质审核通过 */
  infoPass = 3,
  /** 入驻终止 */
  operationEnding = 4,
}

export const enum SHOP_PUNISH_STATUS {
  /** =0 则没有在处罚中 */
  no = 0,
}

interface ShopInfo {
  shopId: string;
  shopName: string;
  shopReviewStatus: SHOP_REVIEW_STATUS;
  shopLogoUrl: string;
  isSubAccount: boolean;
  isPunished: boolean;
}

interface IUrlParams {
  state: string;
  appid: string;
}

const SHOP_INFO_KEY = '__LOCAL_EC_SHOP_INFO';
const saveShopInfo = (data: ShopInfo) => {
  storage.setJSON(SHOP_INFO_KEY, data);
};

const getLocalShopInfo = () => {
  try {
    const info = storage.getJSON(SHOP_INFO_KEY) as ShopInfo;
    if (typeof info.shopReviewStatus === 'undefined' || info.shopReviewStatus === null) {
      throw new Error('本地数据获取异常');
    }
    return info;
  } catch (e) {
    storage.removeItem(SHOP_INFO_KEY);
    return null;
  }
};
const ROLE_KEY = '__LOCAL_EC_ROLE_INFO';
const saveRoleInfo = (data: RoleInfo) => {
  storage.setJSON(ROLE_KEY, data);
};
const getLocalRoleInfo = () => {
  try {
    const role = storage.getJSON(ROLE_KEY) as RoleInfo;
    if (typeof role.rightsInfo === 'undefined' || role.rightsInfo === null) {
      throw new Error('本地数据获取异常');
    }
    return role;
  } catch (e) {
    storage.removeItem(ROLE_KEY);
    return null;
  }
};

const IS_SIGNED_KEY = '__LOCAL_EC_IS_SIGNED';
const IS_VERIFY_KEY = '__LOCAL_EC_IS_VERIFY';
export const saveIsSigned = (data: boolean) => {
  storage.setItem(IS_SIGNED_KEY, String(data));
};
export const saveFaceVerifyStatus = (data: number) => {
  storage.setItem(IS_VERIFY_KEY, String(data));
};
const getLocalIsSigned = () => {
  try {
    const res = storage.getItem(IS_SIGNED_KEY);
    // const res = localStorage.getItem(IS_SIGNED_KEY);
    return Boolean(res);
  } catch (e) {
    return false;
  }
};
const getLocalIsVerify = () => {
  try {
    const res = storage.getItem(IS_VERIFY_KEY);
    return res;
  } catch (e) {
    return false;
  }
};

// 当前审核状态是否需要加载 角色信息
const needRoleInfo = (status?: SHOP_REVIEW_STATUS) => {
  if (isNotDef(status)) {
    return false;
  }
  return [SHOP_REVIEW_STATUS.infoPass].includes(status);
};

export interface State {
  roleInfo?: RoleInfo;
  shopInfo?: ShopInfo;
  isLogin: boolean;
  isSigned: boolean;
  agreementUrl: string;
  faceVerify: VERIFY_STATUS;
  urlParams: IUrlParams;
}

const getInitialData = (): State => {
  const localShopInfo = getLocalShopInfo();
  const localRoleInfo = getLocalRoleInfo();

  const useLocalRoleInfo = localShopInfo && needRoleInfo(localShopInfo.shopReviewStatus) && localRoleInfo;
  const roleInfo = useLocalRoleInfo ? localRoleInfo : undefined;

  const shopInfo = localShopInfo || undefined;
  const isLogin = !!localShopInfo;
  const isSigned = getLocalIsSigned();
  const faceVerify = getLocalIsVerify();

  return {
    agreementUrl: '',
    roleInfo,
    shopInfo,
    isLogin,
    isSigned,
    faceVerify: faceVerify ? Number(faceVerify) : VERIFY_STATUS.UNKNOWN,
    urlParams: { state: '', appid: '' },
  };
};

const initialState: State = getInitialData();

const accountSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    setUserRightsInfo: (state, action: PayloadAction<RoleInfo>) => {
      state.roleInfo = action.payload;
    },
    clearAccountInfo: (state) => {
      state.roleInfo = undefined;
      state.shopInfo = undefined;
    },
    updateLoginState: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setShopInfo: (state, action: PayloadAction<ShopInfo>) => {
      state.shopInfo = action.payload;
    },
    setIsSigned: (state, action: PayloadAction<boolean>) => {
      state.isSigned = action.payload;
    },
    setAgreementUrl: (state, action: PayloadAction<string>) => {
      state.agreementUrl = action.payload;
    },
    setFaceVerify: (state, action) => {
      state.faceVerify = action.payload;
    },
    setUrlParams: (state, action) => {
      state.urlParams = action.payload;
    },
  },
});

const setShopInfo = (data: ShopInfo) => (dispatch: AppDispatch) => {
  dispatch(accountSlice.actions.setShopInfo(data));
};
const setRoleInfo = (data: RoleInfo) => (dispatch: AppDispatch) => {
  dispatch(accountSlice.actions.setUserRightsInfo(data));
};
export const setSigned = (data: boolean) => (dispatch: AppDispatch) => {
  dispatch(accountSlice.actions.setIsSigned(data));
};
const setAgreementUrl = (data: string) => (dispatch: AppDispatch) => {
  dispatch(accountSlice.actions.setAgreementUrl(data));
};

export const clearAccountInfo = (dispatch: AppDispatch) => {
  cookie.quickDel('shop_id');
  aegis.infoAll('[clearAccountInfo]:开始清除account');
  /** 业务侧 */
  logout();
  cookie.quickDel('shop_status');
  dispatch(accountSlice.actions.clearAccountInfo());
  dispatch(accountSlice.actions.updateLoginState(false));
  storage.removeItem(SHOP_INFO_KEY);
  storage.removeItem(ROLE_KEY);
  storage.removeItem(IS_SIGNED_KEY);
};

export const setFaceVerifyStatus = (status: VERIFY_STATUS) => (dispatch: AppDispatch) => {
  dispatch(accountSlice.actions.setFaceVerify(status));
};

export const setUrlParams = (data: IUrlParams) => (dispatch: AppDispatch) => {
  dispatch(accountSlice.actions.setUrlParams(data));
};

export const setLogoutFromHeaderMenu = () => {
  if (HEADER_MENU_URL_LIST.indexOf(window.location.hash.slice(1)) !== -1) {
    storage.setItem('LogoutFromHeaderMenu', '1');
  }
};

export const getLogoutFromHeaderMenu = () => storage.getItem('LogoutFromHeaderMenu');

export const clearLogoutFromHeaderMenu = () => {
  storage.removeItem('LogoutFromHeaderMenu');
};

export const getLogoutFromWujiViolation = () => storage.getItem('LogoutFromWujiViolation');

export const clearLogoutFromWujiViolation = () => {
  storage.removeItem('LogoutFromWujiViolation');
};

/**
 *
 * @param manageShopId 店铺id，子账号登录时需要，店主登录时不需要
 */
export const getAccountInfo = (manageShopId?: string) => async (dispatch: AppDispatch) => {
  try {
    const { shopId, status, shopLogoUrl, name, shopPunishStatus } = await checkShopStatus(manageShopId);
    const shopReviewStatus = status;
    // cookie中的shop_id前端不用，后台接口使用.
    // cookie.quickSet('shop_id', shopId);
    const shopInfo = {
      shopId,
      shopReviewStatus,
      shopLogoUrl,
      shopName: name,
      isSubAccount: !isNotDef(manageShopId),
      isPunished: isNotDef(shopPunishStatus) ? false : shopPunishStatus !== SHOP_PUNISH_STATUS.no,
    };
    dispatch(setShopInfo(shopInfo));
    saveShopInfo(shopInfo);

    // eslint-disable-next-line max-len
    const checkAgreementStatusMap = [SHOP_REVIEW_STATUS.infoPass];
    // 如果有shopId才去查询合同
    if (checkAgreementStatusMap.includes(shopReviewStatus)) {
      const [{ agreementSignStatus, agreementUrl }, { status }] = await Promise.all([
        getSettleAgreementInfo(SETTLE_AGREEMENT_ID),
        getVerifyStatus(),
      ]);
      const isSigned = agreementSignStatus === signStatusEnum.SINGED;
      // 本地保存是否入驻签约
      saveIsSigned(isSigned);
      dispatch(setSigned(isSigned));
      dispatch(setAgreementUrl(agreementUrl));

      // 本地保存是否人脸识别成功
      saveFaceVerifyStatus(status);
      dispatch(setFaceVerifyStatus(status));
    }

    if (needRoleInfo(shopReviewStatus)) {
      const [{ roleInfo }, hasBulkProductRights] = await Promise.all([getUserRightsInfo(), getBulkProductRight()]);
      const externalRoleInfo = {
        ...roleInfo,
        rightsInfo: {
          ...roleInfo.rightsInfo,
          bulkProductRights: {
            rightId: 'bulkProduct',
            rightName: 'bulkProduct',
            isAllowed: hasBulkProductRights ? IS_ALLOWED.yes : IS_ALLOWED.no,
            desc: '批量发布权限',
          },
        },
      };
      dispatch(setRoleInfo(externalRoleInfo));
      saveRoleInfo(externalRoleInfo);
    }
    dispatch(accountSlice.actions.updateLoginState(true));
  } catch (e) {
    const errorText = JSON.stringify(e, Object.getOwnPropertyNames(e), 2);
    // 如果不是为网络错误引起的，则提示重试
    if (!errorText.includes('网络错误')) {
      MessagePlugin.error('本地网络错误，请刷新重试');
      aegis.infoAll('getAccountInfo本地网络错误', errorText);
      return;
    }
    aegis.infoAll('===try catch导致退出登录===', errorText);
    logoutAction();
  }
};

export default accountSlice.reducer;
