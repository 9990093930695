import React, { memo } from 'react';
import Style from './index.module.less';
import { useAppSelector } from 'modules/store';

import { useNavigate } from 'react-router-dom';

interface IProps {
  styleObj: { [props: string]: any },
}
export default memo((props: IProps) => {
  const nav = useNavigate();
  const account = useAppSelector(state => state.account);
  const { styleObj } = props;

  return (
    // 未登录或者是入驻阶段
    !account.shopInfo || !account.isLogin || window.location.hash.indexOf('shopv2') !== -1 || window.location.hash.indexOf('home/ruleCenter') !== -1 ? <>
      <span className={Style.divide} style={styleObj}></span>
      <span className={Style.logoMenu} style={styleObj} onClick={() => { nav('/home/ruleCenter'); }}>商家规则中心</span>
    </> : <></>
  );
});
