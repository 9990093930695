/**
 * @file 运营端路由
 */

import mysterBox from './mysteryBox';
import violation from './violation';
import scoreProduct from './scoreProduct';
import shopDataCenter from './shopDataCenter';
import kingCommission from './kingCommission';
import liveProductConfig from './liveProductConfig';
import yiFanBox from './yiFanBox';
import boxLevelDataConfig from './boxLevelDataConfig';
import boxAdvance from './boxAdvance';
import contentHeating from './contentHeating';
import mysterProfit from './mysteryProfit';
import biddingDelivery from './biddingDelivery';
import brandAdvertisement from './brandAdvertisement';
import daziDeliver from './daziDeliver';
import crowdPack from './crowdPack';

import auditLog from './auditLog';
import type { RouterType } from 'types';
import simulation from './simulation';

const opRouters: RouterType[] = [
  ...violation,
  ...mysterBox,
  ...scoreProduct,
  ...shopDataCenter,
  ...kingCommission,
  ...simulation,
  ...liveProductConfig,
  ...yiFanBox,
  ...boxLevelDataConfig,
  ...auditLog,
  ...boxAdvance,
  ...contentHeating,
  ...biddingDelivery,
  ...brandAdvertisement,
  ...mysterProfit,
  ...daziDeliver,
  ...crowdPack,
];

export default opRouters;
