export const enum ErrCode {
  Succ = 0,
  // ======= 接入层 错误码 ======
  // ErrTypeBadData ...
  ErrTypeBadData = 400,
  // ErrTypeNotFound ...
  ErrTypeNotFound = 404,
  // ErrTypeParseData ...
  ErrTypeParseData = 500,
  // ErrTypeNetError ...
  ErrTypeNetError = 501,
  // ErrTypeIllegalReq ...
  ErrTypeIllegalReq = 502,
  // ErrTypeInternalError ...
  ErrTypeInternalError = 503,
  // ErrKeyProcessError ...
  ErrTypeKeyProcessError = 504,
  // ======= 接入层 错误码 End ======
  // ErrInternal 内部错误
  ErrInternal = -999,
  // ======= login 错误码 ========
  // ErrNoConfig 配置不存在
  ErrNoConfig = -10000, // 配置不存在
  // ErrSessionExpired 登录态非法
  ErrSessionExpired = -10001,
  // ErrIlegalParams 参数不对
  ErrIlegalParams = -10002,
  // ErrInvalidCode 去开平检测，Code不对
  ErrInvalidCode = -10003,
  // ErrGenTinyID tinyid 生成失败
  ErrGenTinyID = -10004,
  // ErrGetOpenInfo 获取开平头像昵称错误
  ErrGetOpenInfo = -10005,
  // ErrLogout 退出登录失败
  ErrLogout = -10006,
  // ErrGenSession 生成tinyid session失败
  ErrGenSession = -10007,
  // ErrGetOauthInfo 获取open session 失败
  ErrGetOauthInfo = -10008,
  // ErrGetUID 查询uid失败
  ErrGetUID = -10009,
  // ErrOauthInfoNotExist Session不存在
  ErrOauthInfoNotExist = -10010,

  // ErrLoginedCB 登录成功后回调失败
  ErrLoginedCB = -10100,
  // ErrBusiLoginCB 业务方自定义登录回调失败
  ErrBusiLoginCB = -10101,
  // ErrBusiAuthCB 业务方自定义鉴权回调失败
  ErrBusiAuthCB = -10102,
  // ErrBusiLogoutCB 业务自定义退出登录回调失败
  ErrBusiLogoutCB = -10103,
  // ErrInvalidPhoneNum 手机号码不合法
  ErrInvalidPhoneNum = -10104,
  // ErrNeedCaptchaVerify
  ErrNeedCaptchaVerify = -10105,
  // ErrInvalidSecCheckSig 安检签名非法
  ErrInvalidSecCheckSig = -10106,
  // ErrInvalidCaptchaSig 验证码签名非法
  ErrInvalidCaptchaSig = -10107,
  // ErrGetSMSCode 获取短信验证码失败
  ErrGetSMSCode = -10108,
  // ErrInvalidSession 无效的票据
  ErrInvalidSession = -10109,
  // ErrSMSIntervalNotMet 下发间隔不满足
  ErrSMSIntervalNotMet = -10110,
  // ErrSmsCaptchaRisky 防水墙风控
  ErrSmsCaptchaRisky = -10111,
  // ======= login 错误码 End ========
  // ErrSelectNoNode 寻址失败
  ErrSelectNoNode = -10200,
  // ErrAuthTerribleError 异常鉴权失败
  ErrAuthTerribleError = -10201,
  // ErrAuthInvalidKey 票据校验不通过
  ErrAuthInvalidKey = -10202,
  // ErrAuthError 校验失败
  ErrAuthError = -10203,
  // ErrAuthKeyParseError 票据解析失败
  ErrAuthKeyParseError = -10204,
  // ErrRefreshAuthError 刷新票据失败
  ErrRefreshAuthError = -10205,
  // ErrPhoneCodeError 手机短信校验失败
  ErrPhoneCodeError = -10206,
  // ErrPhoneCarrierError 手机号网关取号校验失败
  ErrPhoneCarrierError = -10207,
  // ErrInvalidToken token非法
  ErrInvalidTokenError = -10208,
  // ErrExpiredToken token过期
  ErrExpiredTokenError = -10209,

  expire1 = -10017,
  expire2 = -10018,
}

export const ErrText: Record<number, string> = {
  [ErrCode.Succ]: '成功',
  [ErrCode.ErrTypeBadData]: '数据异常',
  [ErrCode.ErrTypeNotFound]: '未知错误',
  [ErrCode.ErrTypeParseData]: '数据异常',
  [ErrCode.ErrTypeNetError]: '网络异常',
  [ErrCode.ErrTypeIllegalReq]: '非法请求',
  [ErrCode.ErrTypeInternalError]: 'ErrTypeInternalError',
  [ErrCode.ErrTypeKeyProcessError]: 'ErrKeyProcessError',
  [ErrCode.ErrInternal]: '内部错误',
  [ErrCode.ErrNoConfig]: '配置不存在',
  [ErrCode.ErrSessionExpired]: '登录态非法',
  [ErrCode.ErrIlegalParams]: '参数不对',
  [ErrCode.ErrInvalidCode]: '去开平检测，Code不对',
  [ErrCode.ErrGenTinyID]: '生成失败',
  [ErrCode.ErrGetOpenInfo]: '获取开平头像昵称错误',
  [ErrCode.ErrLogout]: '退出登录失败',
  [ErrCode.ErrGenSession]: 'session失败',
  [ErrCode.ErrGetOauthInfo]: '失败',
  [ErrCode.ErrGetUID]: '查询uid失败',
  [ErrCode.ErrOauthInfoNotExist]: 'Session不存在',
  [ErrCode.ErrLoginedCB]: '登录成功后回调失败',
  /** 业务方自定义登录回调失败 */
  [ErrCode.ErrBusiLoginCB]: '登录回调失败',
  /** 业务方自定义鉴权回调失败 */
  [ErrCode.ErrBusiAuthCB]: '回调失败',
  [ErrCode.ErrBusiLogoutCB]: '业务自定义退出登录回调失败',
  [ErrCode.ErrInvalidPhoneNum]: '手机号码不合法',
  [ErrCode.ErrNeedCaptchaVerify]: '需要验证码校验',
  [ErrCode.ErrInvalidSecCheckSig]: '安检签名非法',
  [ErrCode.ErrInvalidCaptchaSig]: '验证码签名非法',
  [ErrCode.ErrGetSMSCode]: '获取短信验证码失败',
  [ErrCode.ErrInvalidSession]: '无效的票据',
  [ErrCode.ErrSMSIntervalNotMet]: '验证码获取过于频繁',
  [ErrCode.ErrSmsCaptchaRisky]: '防水墙风控',
  [ErrCode.ErrSelectNoNode]: '寻址失败',
  [ErrCode.ErrAuthTerribleError]: '异常鉴权失败',
  [ErrCode.ErrAuthInvalidKey]: '票据校验不通过',
  [ErrCode.ErrAuthError]: '校验失败',
  [ErrCode.ErrAuthKeyParseError]: '票据解析失败',
  [ErrCode.ErrRefreshAuthError]: '刷新票据失败',
  [ErrCode.ErrPhoneCodeError]: '手机短信校验失败',
  [ErrCode.ErrPhoneCarrierError]: '手机号网关取号校验失败',
  [ErrCode.ErrInvalidTokenError]: '验证码错误或已失效',
  [ErrCode.ErrExpiredTokenError]: '验证码错误或已失效',
  [ErrCode.expire1]: '验证码错误或已失效',
  [ErrCode.expire2]: '验证码错误或已失效',
};

export const tcaptchaErrCode: Record<number, string> = {
  1001: '验证码加载错误',
  /** show方法执行错误，出现这种情况有两种原因:
   * ①接入JS已成功加载,show方法已经可以被调用,但在调用show方法的时候,frame.js还未加载成功
   * ②在调用show方法的时候, 引入验证码sdk的页面未加载完成,常见的情况是在head标签里引入了验证码sdk. 这个时候虽然验证码sdk已经成功加载,但业务方的html尚未完成解析
   */
  1002: '验证码打开错误',
  /** frame.js加载超时，网络问题，在超过15s后,frame.js仍未响应 */
  1003: '网络异常',
  /** frame.js加载错误，网络问题，frame.js响应,但响应异常,比如说状态码为404等 */
  1004: '网络异常',
  /** frame.js运行错误，执行frame.js时出现错误 */
  1005: '验证码执行异常',
  /** 拉取验证码配置（prehandle请求）错误/超时
   * ①验证码后台判断此次请求不合法,主动返回了非200状态码的响应
   * ②网络问题
   */
  1006: '获取验证码配置异常',
  /** 预加载模板文件加载失败
   * ①drag_ele.html文件没有正常响应
   * ②dy-ele.xxx.js文件没有正常响应
   */
  1007: '预加载模板文件加载失败',
  /** 旧架构滑块和vtt验证码的js文件加载超时或失败，网络问题 */
  1008: '网络异常',
  /** jquery加载错误，网络问题 */
  1009: '网络异常',
  /** 验证码js加载错误，该错误码目前暂未启用，不会出现 */
  1010: '网络异常',
  /** 验证码js运行错误，验证码内部js运行错误 */
  1011: '验证码运行异常',
  /** 刷新连续错误3次，网络问题 */
  1012: '网络异常',
  /** 验证网络连续错误3次，网络问题 */
  1013: '网络异常',
};
