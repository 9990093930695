import { SHOP_REVIEW_STATUS } from 'modules/account';
import { useAppSelector } from 'modules/store';
import React from 'react';
import { roleIdEnum } from 'services/account';
import { Popup } from 'tdesign-react';
import s from './HeaderIcon.module.less';

const ShopInfo = () => {
  const { shopInfo, roleInfo } = useAppSelector(state => state.account);

  if (!shopInfo || shopInfo.shopReviewStatus === SHOP_REVIEW_STATUS.empty) {
    return null;
  }

  return (
    <Popup
      overlayStyle={() => ({
        padding: 16,
      })}
      content={
        <div className={s.shopDetails}>
          <div className={s.row}>
            <div className={s.label}>店铺名称：</div>
            <div className={s.value}>{shopInfo?.shopName}</div>
          </div>

          <div className={s.row}>
            <div className={s.label}>店铺ID：</div>
            <div className={s.value}>{shopInfo?.shopId}</div>
          </div>

          {roleInfo && (
            <div className={s.row}>
              <div className={s.label}>当前角色：</div>
              <div className={s.value}>{roleIdEnum.value2Label(roleInfo.roleId)}</div>
            </div>
          )}
        </div>
      }
    >
      <div className={s.shopInfo}>
        {/* 店铺logo改成非加密上传后，直接url展示 */}
        <img className={s.logo} src={shopInfo?.shopLogoUrl || ''} />
        <div className={s.name}>{shopInfo?.shopName}</div>
        <div className={s.line} />
      </div>
    </Popup>
  );
};

export default ShopInfo;
