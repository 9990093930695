const cookie = {
  get(n: string): string {
    const m = document.cookie.match(new RegExp(`(^| )${n}=([^;]*)(;|$)`));
    return !m ? '' : decodeURIComponent(m[2]);
  },

  set(name: string, value: string, domain: string, path: string, hour: number): void {
    const expire = new Date();
    expire.setTime(expire.getTime() + (hour ? 3600000 * hour : 30 * 24 * 60 * 60 * 1000));
    const domainStr = domain ? `domain=${domain};` : '';
    const cookieStr = `${name}=${value};expires=${expire.toUTCString()};path=${path || '/'};${domainStr}`;
    document.cookie = cookieStr;
  },

  del(name: string, domain: string, path: string): void {
    const domainStr = domain ? `domain=${domain};` : '';
    document.cookie = `${name}=; expires=Mon, 26 Jul 1997 05:00:00 GMT; path=${path || '/'}; ${domainStr}`;
  },

  clear(): void {
    // eslint-disable-next-line prefer-regex-literals
    const rs = document.cookie.match(new RegExp('([^ ;][^;]*)(?=(=[^;]*)(;|$))', 'gi'));
    rs?.forEach((v: string) => {
      document.cookie = `${v}=;expires=Mon, 26 Jul 1997 05:00:00 GMT; path=/; `;
    });
  },

  uin(): any {
    const u = cookie.get('uin');
    return !u ? null : parseInt(u.substring(1, u.length), 10);
  },

  domain: 'qq.com',

  path: '/',

  defaultExpires: 24,

  quickSet(name: string, value: string) {
    cookie.set(name, value, cookie.domain, cookie.path, cookie.defaultExpires);
  },

  quickDel(name: string) {
    cookie.del(name, cookie.domain, cookie.path);
  },
};

export default cookie;
