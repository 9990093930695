const Cgi = {
  checkShop: `https://${import.meta.env.VITE_API_HOST}/trpc.ecom.qqshop_cms_http_svr.QQShopCmsHttp/GetOwnShopInfo`,
  logout: `https://${import.meta.env.VITE_API_HOST}/trpc.ecom.qqshop_cms_http_svr.QQShopCmsHttp/Logout`,
  loginShop: `https://${import.meta.env.VITE_API_HOST}/trpc.ecom.qqshop_account_bind_svr.AccountBind/LoginShop`,
  getRights: `https://${import.meta.env.VITE_API_HOST}/trpc.ecom.qshop_account_http_svr.QShopAccountHttp/GetRights`,
};

const Cfg = {
  luAppId: Number(import.meta.env.VITE_LU_APP_ID),
};

export { Cgi, Cfg };
