import React, { memo, useState } from 'react';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';
import { Menu, MenuValue } from 'tdesign-react';
import router, { RouterType } from 'router';
import { resolve } from 'utils/path';
import { RootState, useAppSelector } from 'modules/store';
import MenuLogo from './MenuLogo';
import Style from './Menu.module.less';
import { RoleInfo } from 'services/account';

const { SubMenu, MenuItem, HeadMenu } = Menu;

interface IMenuProps {
  showLogo?: boolean;
  showOperation?: boolean;
}

const renderMenuItems = (
  menu: RouterType[],
  navigate: ReturnType<typeof useNavigate>,
  account: RootState['account'],
  parentPath = '',
) =>
  menu.map((item) => {
    const { children, meta, path, checkRole } = item;

    if (!meta) {
      // 无meta信息，路由不显示为菜单
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (checkRole && !checkRole(account.roleInfo!)) {
      return null;
    }

    const { Icon, title } = meta || {};
    const routerPath = resolve(parentPath, path);

    const checkRolePassedChildren = children
      ? children
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .filter(child => (child.checkRole ? child.checkRole(account.roleInfo!) : true))
        .filter(child => child.meta)
      : [];

    if (!checkRolePassedChildren || checkRolePassedChildren.length === 0) {
      if (!item.Component && !item.redirect) {
        return null;
      }
      return (
        <MenuItem
          key={routerPath}
          value={routerPath}
          icon={Icon ? <Icon /> : undefined}
          onClick={() => navigate(routerPath)}
        >
          {title}
        </MenuItem>
      );
    }

    return (
      <SubMenu key={routerPath} value={routerPath} title={title} icon={Icon ? <Icon /> : undefined}>
        {renderMenuItems(checkRolePassedChildren, navigate, account, routerPath)}
      </SubMenu>
    );
  });

/**
 * 顶部菜单
 */
export const HeaderMenu = memo(() => {
  const { global: globalState, account } = useAppSelector(state => ({
    global: state.global,
    account: state.account,
  }));
  const location = useLocation();
  const [active, setActive] = useState<MenuValue>(location.pathname); // todo
  const navigate = useNavigate();

  return (
    <HeadMenu
      expandType='popup'
      style={{ marginBottom: 20 }}
      value={active}
      theme={globalState.theme}
      onChange={v => setActive(v)}
    >
      {renderMenuItems(router, navigate, account)}
    </HeadMenu>
  );
});

interface MatchResult {
  path: string | null;
  isMatchPaths: boolean;
}

const matchRoutes = (routerList: RouterType[], path: string, roleInfo?: RoleInfo, parentPath?: string): MatchResult => {
  const len = routerList.length;

  const fullPath = (p: string) => resolve(parentPath, p);
  const matchResult: MatchResult = {
    path: null,
    isMatchPaths: false,
  };

  for (let i = 0; i < len; i++) {
    const route = routerList[i];
    const curPath = fullPath(route.path);

    const hasRight = !route.checkRole ? true : route.checkRole?.(roleInfo);
    if (hasRight) {
      if (matchPath(curPath, path)) {
        if (!matchResult.isMatchPaths) {
          matchResult.isMatchPaths = false;
          matchResult.path = curPath;
        }
      }
      if (route.matchPaths) {
        for (let j = 0; j < route.matchPaths.length; j++) {
          if (matchResult.isMatchPaths) {
            break;
          }
          const cur = route.matchPaths[j];
          if (matchPath(cur, path)) {
            matchResult.isMatchPaths = true;
            matchResult.path = curPath;
          }
        }
      }
      if (route.children) {
        if (!matchResult.isMatchPaths) {
          const result = matchRoutes(route.children, path, roleInfo, curPath);
          if (result.path) {
            matchResult.isMatchPaths = true;
            matchResult.path = result.path;
          }
        }
      }
    }
  }

  return matchResult;
};

/**
 * 左侧菜单
 */
export default (props: IMenuProps) => {
  const location = useLocation();
  const { global: globalState, account } = useAppSelector(state => ({
    global: state.global,
    account: state.account,
  }));
  const navigate = useNavigate();

  const { version } = globalState;
  const bottomText = globalState.collapsed ? version : 'QQ小店';
  const width = 232;
  const routerList = !account.roleInfo ? [] : router;
  const roleInfo = useAppSelector(state => state.account.roleInfo);

  const value = matchRoutes(routerList, location.pathname, roleInfo, '');

  return (
    <Menu
      width={`${width}px`}
      style={{ flexShrink: 0, height: '100%' }}
      // value={location.pathname}
      value={value.path || ''}
      theme={globalState.theme}
      collapsed={globalState.collapsed}
      operations={props.showOperation ? <div className={Style.menuTip}>{bottomText}</div> : undefined}
      logo={props.showLogo ? <MenuLogo collapsed={globalState.collapsed} /> : undefined}
    >
      {renderMenuItems(routerList, navigate, account)}
    </Menu>
  );
};
