import React, { useEffect, useState } from 'react';
import { Layout, MessagePlugin } from 'tdesign-react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import throttle from 'lodash/throttle';
import aegis from 'utils/aegis';

import { useAppSelector, useAppDispatch } from 'modules/store';
import { selectGlobal, toggleMenu, ELayout } from 'modules/global';
import {
  getAccountInfo,
  SHOP_REVIEW_STATUS,
  getLogoutFromHeaderMenu,
  clearLogoutFromHeaderMenu,
} from 'modules/account';
import { pageGrantInfo, GRANT_STATUS, getCode } from 'services/productUtilsService';
import { VERIFY_STATUS } from 'services/faceVerify';

import { HOME_PATH, LOGIN_PATH, HEADER_MENU_URL_LIST } from 'router';
import { useHandlePathPermission } from 'router/hooks';
import { getShopV2Routes } from 'router/modules/shopv2';

import LayoutMap from './components/Container';
import Style from './index.module.less';

/**
 * 判断是否需要重定向到登录页
 * 以下页面不需要
 * 1、QQ绑定页面
 * 2、运营端页面
 *
 * @param path 页面路由
 * @returns boolean
 */
const isNeedLogin = (path: string) => {
  const qqBindsPages = ['/shop/bindShop', '/market/mainPage'];
  if (qqBindsPages.includes(path)) {
    return false;
  }
  if (path.indexOf('/op/') > -1) {
    // hack 如果是shadow dom内嵌页面，需要将body position 改为 relative
    // 用于修复tdesign下拉框选项偏移问题
    document.body.style.position = 'relative';
    return false;
  }
  return true;
};

const App = React.memo(() => {
  const globalState = useAppSelector(selectGlobal);
  const account = useAppSelector((state) => state.account);
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const { handlePathPermission } = useHandlePathPermission();

  const Container = LayoutMap[globalState.isFullPage ? ELayout.fullPage : globalState.layout];

  useEffect(() => {
    const handleResize = throttle(() => {
      if (window.innerWidth < 900) {
        dispatch(toggleMenu(true));
      } else if (window.innerWidth > 1000) {
        dispatch(toggleMenu(false));
      }
    }, 100);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (account.isLogin) {
      dispatch(getAccountInfo(account.shopInfo?.shopId));
    }
  }, []);

  // eslint-disable-next-line complexity
  const handleJumpHome = async () => {
    const providerState = urlParams.get('state');
    const providerAppid = urlParams.get('appid');
    // 从铺得快或者其他平台过来
    if (providerState && providerAppid && !loading) {
      setLoading(true);
      const grantInfos = await pageGrantInfo({ userId: account?.shopInfo?.shopId || '', granted: false });
      if (grantInfos.length === 0) {
        MessagePlugin.error('铺货工具获取失败，请联系管理员');
        setLoading(false);
        navigate(HOME_PATH, { replace: true });
        return;
      }
      const puDeKuai = grantInfos.find((item) => item.appDetail.appInfo.appid === providerAppid);
      // 已经授权过
      if (puDeKuai?.status === GRANT_STATUS.GRANTED) {
        aegis.infoAll('[获取授权code]:', 'account===:', account, 'pathname===:', pathname);
        const getCodeRsp = await getCode({
          shopName: account?.shopInfo?.shopName || '',
          appid: providerAppid,
        });
        setLoading(false);
        if (getCodeRsp.code !== 0) {
          MessagePlugin.error('获取供应商校验code失败');
          return;
        }
        window.location.replace(
          `${puDeKuai.appDetail.homePageUrl}?state=${providerState}&code=${getCodeRsp.data.code}&platformShopId=${account?.shopInfo?.shopId}&source=second`,
        );
        return;
      }
      setLoading(false);
      // 未授权
      navigate('/product/UtilsAuthorizationPage', {
        replace: true,
        state: { grantInfo: { appDetail: puDeKuai?.appDetail }, state: providerState },
      });
      return;
    }
    navigate(HOME_PATH, { replace: true });
  };

  // eslint-disable-next-line complexity
  useEffect(() => {
    // 路由权限溢出 - 监听路由判断是否有权限
    const res = handlePathPermission();
    if (!res) {
      return;
    }

    // 当前路由是头部导航栏。且如果没有点击过退出，则跳转到此路由。
    // 如果路径为头部导航栏，但是点击了退出，则需要跳转到login.
    if (HEADER_MENU_URL_LIST.indexOf(pathname) !== -1) {
      if (!getLogoutFromHeaderMenu()) return;
      clearLogoutFromHeaderMenu();
    }

    // 如果是不需要验证登录的页面，直接return
    if (!isNeedLogin(pathname)) {
      return;
    }

    // 未登录
    if (!account.shopInfo || !account.isLogin) {
      const providerState = urlParams.get('state');
      const providerAppid = urlParams.get('appid');
      const loginPath =
        providerState && providerAppid ? `/login?state=${providerState}&appid=${providerAppid}` : '/login';
      aegis.infoAll('[clearAccountInfo]:清除完毕，退出登录', loginPath, account);
      navigate(loginPath, { replace: true });
      return;
    }

    const { shopInfo } = account;
    const shopV2Routes = getShopV2Routes();
    /** 店铺入驻路由 */
    const SETTLED_PATH = shopV2Routes.settled;
    /** 显示店铺入驻结果 */
    const STATUS_PATH = shopV2Routes.shopStatus;
    /** ============================== 以下为已登录逻辑 ======================================== */
    // 未入驻
    if (shopInfo.shopReviewStatus === SHOP_REVIEW_STATUS.empty) {
      if (![SETTLED_PATH].includes(pathname)) {
        navigate(SETTLED_PATH);
      }
      return;
    }

    // 审核中 | 审核拒绝
    if ([SHOP_REVIEW_STATUS.infoPending, SHOP_REVIEW_STATUS.infoReject].includes(shopInfo.shopReviewStatus)) {
      if (shopInfo.shopReviewStatus === SHOP_REVIEW_STATUS.infoReject && pathname === SETTLED_PATH) {
        // 如果审核失败并且在编辑入驻信息，不跳转
        return;
      }
      navigate(STATUS_PATH);
      return;
    }

    // 没有人脸识别
    if (VERIFY_STATUS.FACE_VERIFY_OK !== account.faceVerify) {
      if (account.faceVerify !== VERIFY_STATUS.MANUAL_VERIFY_OK || !account.isSigned) {
        navigate('/faceVerify');
        return;
      }
    }
    // 入驻没签约
    if (!account.isSigned) {
      navigate('/faceVerify');
      return;
    }

    // 入驻已签约
    if (account.isSigned) {
      if (
        // prettier-ignore
        account.faceVerify === VERIFY_STATUS.FACE_VERIFY_OK
        || account.faceVerify === VERIFY_STATUS.MANUAL_VERIFY_OK
      ) {
        if (pathname === '/faceVerify') {
          navigate(HOME_PATH, { replace: true });
        }
      }
    }

    // 审核通过 & 已签约
    if ([LOGIN_PATH, STATUS_PATH, SETTLED_PATH].includes(pathname)) {
      handleJumpHome();
    }
  }, [account, pathname]);

  return (
    <Layout className={Style.mainPanel}>
      <Container />
      {/* <Drawer
        destroyOnClose
        visible={true}
        size="458px"
        footer={false}
        header={<div>页面配置</div>}
        onClose={() => dispatch(toggleSetting())}
      >
        <Setting />
      </Drawer> */}
    </Layout>
  );
});

App.displayName = 'App';
export default App;
