const Icon = {
  loginPost: 'https://fcccdn.qq.com/infoop/693541f13f6116a6b350193460bef492_1653449363605.png',
  setteldCertification: 'https://fcccdn.qq.com/infoop/3e20bcb62a7cb5e7f774f17617f24e1b_1653561975430.png',
  merchantPerson: 'https://fcccdn.qq.com/infoop/9314c90ce6d3289788f2570356575fb7_1654687067089.png',
  merchantCompany: 'https://fcccdn.qq.com/infoop/67300785d96fb835bd318e0c360c9f66_1654687152705.png',
};
const Cgi = {
  inviteUser: `https://${import.meta.env.VITE_API_HOST}/trpc.ecom.qshop_account_http_svr.QShopAccountHttp/InviteUser`,
  getSettleFaceCode: `https://${
    import.meta.env.VITE_API_HOST
  }/trpc.ecom.qqshop_cms_http_svr.QQShopCmsHttp/GetSettleFaceCode`,
  getUserIdentity: `https://${
    import.meta.env.VITE_API_HOST
  }/trpc.ecom.qqshop_account_bind_svr.AccountBind/GetUserIdentity`,
  addShop: `https://${import.meta.env.VITE_API_HOST}/trpc.ecom.qqshop_cms_http_svr.QQShopCmsHttp/AddShop`,
  getShopInfo: `https://${import.meta.env.VITE_API_HOST}/trpc.ecom.qqshop_cms_http_svr.QQShopCmsHttp/GetOwnShopInfo`,
};

export { Icon, Cgi };
