/**
 * @file 盲盒内部路由
 */
import { lazy } from 'react';
import type { RouterType } from 'types';

const mysterBox: RouterType[] = [
  {
    // 新增盲盒
    path: '/op/box/create',
    Component: lazy(() => import('opPages/MysteryBox/Edit')),
    isFullPage: true,
  },
  {
    // 编辑盲盒
    path: '/op/box/edit/:id',
    Component: lazy(() => import('opPages/MysteryBox/Edit')),
    isFullPage: true,
  },
  {
    // 新增编辑盲盒
    path: '/op/box/detail/:id',
    Component: lazy(() => import('opPages/MysteryBox/Detail')),
    isFullPage: true,
  },
  {
    // 盲盒管理
    path: '/op/box/manage',
    Component: lazy(() => import('opPages/MysteryBox/Manage')),
    isFullPage: true,
  },
];

export default mysterBox;
