import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from 'modules/store';
import cloneDeep from 'lodash/cloneDeep';
import { SubmitShopDeclarationReq, SubmitOrderDeclarationReq, Order } from 'services/reportCenter';
import { updateObjByChainKeys } from 'utils';

const namespace = 'reportCenter';

const initialState: {
  // 店铺报备快照
  shopFormSnapshot: DeepPartial<SubmitShopDeclarationReq>;
  // 订单报备快照
  orderFormSnapshot: DeepPartial<SubmitOrderDeclarationReq>;
  // 选中的订单快照
  chosenOrderFormSnapshot: Order[];
  // 报备详情的订单信息
  orderListFormSnapshot: Order[];
} = {
  shopFormSnapshot: {},
  orderFormSnapshot: {},
  chosenOrderFormSnapshot: [],
  orderListFormSnapshot:[],
};

const reportCenterSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    reset(state) {
      state.shopFormSnapshot = {};
      state.orderFormSnapshot = {};
      state.chosenOrderFormSnapshot = [];
      state.orderListFormSnapshot = [];
    },

    // 链式更新快照的属性
    updateShopSnapshot: (state, action: PayloadAction<Record<string, any>>) => {
      const clone = cloneDeep(state.shopFormSnapshot);
      updateObjByChainKeys(clone, action.payload);
      state.shopFormSnapshot = clone;
    },
    // 全量写快照，用于表单从服务端更新
    writeShopSnapshot: (state, action: PayloadAction<DeepPartial<SubmitShopDeclarationReq>>) => {
      state.shopFormSnapshot = action.payload;
    },

    // 链式更新快照的属性
    updateOrderSnapshot: (state, action: PayloadAction<Record<string, any>>) => {
      const clone = cloneDeep(state.orderFormSnapshot);
      updateObjByChainKeys(clone, action.payload);
      state.orderFormSnapshot = clone;
    },
    // 全量写快照，用于表单从服务端更新
    writeOrderSnapshot: (state, action: PayloadAction<DeepPartial<SubmitOrderDeclarationReq>>) => {
      state.orderFormSnapshot = action.payload;
    },

    // 全量写快照，用于表单从服务端更新
    writeChosenOrderSnapshot: (state, action: PayloadAction<Order[]>) => {
      state.chosenOrderFormSnapshot = action.payload;
    },
    // 删除某个选中的订单
    delChosenOrderSnapshot: (state, action: PayloadAction<string>) => {
      state.chosenOrderFormSnapshot = state.chosenOrderFormSnapshot.filter(item => item.orderId !== action.payload);
    },
    // 删除某些订单
    delChosenOrdersSnapshot: (state, action: PayloadAction<string[]>) => {
      state.chosenOrderFormSnapshot =
        state.chosenOrderFormSnapshot.filter(item => !action.payload.includes(item.orderId));
    },
     // 全量写快照，用于表单从服务端更新
     writeOrderListFormSnapshot: (state, action: PayloadAction<Order[]>) => {
      state.orderListFormSnapshot = action.payload;
    },
  },
});

// ================================= 店铺报备快照 =================================
// 链式更新快照的属性
export const updateShopSnapshot = (data: Record<string, any>) => (dispatch: AppDispatch) => {
  dispatch(reportCenterSlice.actions.updateShopSnapshot(data));
};

// 全量写快照，用于表单从服务端更新
export const writeShopSnapshot = (data: DeepPartial<SubmitShopDeclarationReq>) => (dispatch: AppDispatch) => {
  dispatch(reportCenterSlice.actions.writeShopSnapshot(data));
};

// 链式更新快照的属性
export const updateOrderSnapshot = (data: Record<string, any>) => (dispatch: AppDispatch) => {
  dispatch(reportCenterSlice.actions.updateOrderSnapshot(data));
};

// 全量写快照，用于表单从服务端更新
export const writeOrderSnapshot = (data: DeepPartial<SubmitOrderDeclarationReq>) => (dispatch: AppDispatch) => {
  dispatch(reportCenterSlice.actions.writeOrderSnapshot(data));
};

// 全量写快照，用于表单从服务端更新
export const writeChosenOrderSnapshot = (data: Order[]) => (dispatch: AppDispatch) => {
  dispatch(reportCenterSlice.actions.writeChosenOrderSnapshot(data));
};
// 删除某个选中的订单
export const delChosenOrderSnapshot = (orderId: string) => (dispatch: AppDispatch) => {
  dispatch(reportCenterSlice.actions.delChosenOrderSnapshot(orderId));
};
// 删除某些的订单
export const delChosenOrdersSnapshot = (orderIds: string[]) => (dispatch: AppDispatch) => {
  dispatch(reportCenterSlice.actions.delChosenOrdersSnapshot(orderIds));
};
// 全量写快照，用于表单从服务端更新
export const writeOrderListFormSnapshot = (orderIds: Order[]) => (dispatch: AppDispatch) => {
  dispatch(reportCenterSlice.actions.writeOrderListFormSnapshot(orderIds));
};

// 重置清空全部快照
export const clearSnapshot = (dispatch: AppDispatch) => {
  dispatch(reportCenterSlice.actions.reset());
};

export default reportCenterSlice.reducer;
